
  import Vue, { PropType } from 'vue';
  import { oc as Optional } from "ts-optchain";

  import responsiveImage from '@/main/webapp/vue/components/ui/content/responsive-image/index.vue';
  import filetypeIcon from '@/main/webapp/vue/components/ui/content/filetype-icon/index.vue';

  import { ItemContent, ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

  export default Vue.extend({
    components: {
      responsiveImage,
      filetypeIcon
    },
    props: {
      contents: {
        type: Array as PropType<ItemContent[]>,
        required: false
      },
      type: {
        type: String as PropType<ItemContentType>,
        default: ItemContentType.THUMBNAIL
      },
      navLinks: {
        type: Object as PropType<NavigationLinks>
      },
      autoPlayInterval: {
        type: Number,
        default: 0 // disable as default
      },
      itemIndex: {
        type: Number,
        default: null
      },
      slideIndex: {
        type: Number,
        default: 0
      }
    },
    computed: {
      navLink(): string {
        let link: string | undefined = NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, this.navLinks)?.href;
        return link || '#';
      },
      parsedContents: function(): ItemContent[] {
        let contentLength: number = Optional(this.contents).length(0);
        if (contentLength > 0) {
          return this.contents;
        } else {
          return [
            ItemContent.prototype.generateNoImageContent(
              true,
              (this as any).$properties.content.image.placeholder.noImage,
              this.navLink)
          ];
        }
      }
    },
    methods: {
      onImageClick(): void {
        this.$emit('image-click', this.currentSlideIndex);
      },
      clearSlideIndex(): void {
        this.currentSlideIndex = 0;
      }
    },
    data() {
      return {
        currentSlideIndex: this.slideIndex as number,
        itemContentTypes: ItemContentType
      };
    },
    watch: {
      currentSlideIndex(): void { // To calculate itempage with slidIndex
        this.$emit('slide-index-change', this.currentSlideIndex);
      },
      contents(): void {
        this.clearSlideIndex();
      }
    }
  });
