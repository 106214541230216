
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import applicationConfiguration from "@/main/webapp/vue/config/application/configuration";
  import messages from "@/main/webapp/vue/components/web-socket/web-socket-status/messages.json";

  import { oc as Optional } from "ts-optchain";

  import { IFrame } from "@stomp/stompjs";

  import webSocketProgress from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-progress/index.vue";

  import { WebSocketIntegrationService } from "@/main/webapp/vue/services/WebSocketIntegrationService";
  import { WebSocketTaskStatus } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskStatus";
  import { WebSocketRequest } from "@/main/webapp/vue/model/api/web-socket/WebSocketRequest";
  import { WebSocketResponse } from "@/main/webapp/vue/model/api/web-socket/WebSocketResponse";
  import { WebSocketResponseType } from "@/main/webapp/vue/model/api/web-socket/WebSocketResponseType";
  import { WebSocketProgressPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketProgressPayload";
  import { WebSocketLinkPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketLinkPayload";
  import { WebSocketTaskPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskPayload";
  import { WebSocketErrorPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketErrorPayload";
  import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";
  import { WebSocketRequestType } from "@/main/webapp/vue/model/api/web-socket/WebSocketRequestType";
  import { WebSocketMessagePayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketMessagePayload";
  import { WebSocketCancelTaskPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketCancelTaskPayload";

  export default Vue.extend({
    components: {
      webSocketProgress
    },
    props: {
      definition: {
        type: Object as PropType<WebSocketTaskDefinition>,
        required: true
      },
      payload: {
        type: Object as PropType<WebSocketTaskPayload>,
        default: null
      },
      execute: {
        type: Boolean,
        default: false
      },
      statusCheck: {
        type: Boolean,
        default: true
      },
      showSpinner: {
        type: Boolean,
        default: false
      },
      cancelResult: {
        type: Boolean,
        default: false
      },
      progressTitle: {
        type: String,
        default: ""
      },
      initialProgressText: {
        type: String,
        default: ""
      },
      progressTextLong: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        status: WebSocketTaskStatus.IDLE,
        reconnections: 1 as number,
        progressVisible: false as boolean,
        progressText: this.initialProgressText as string | undefined,
        progressValue: 0 as number | undefined,
        progressMax: 100 as number | undefined,
        displayDownloadButton: true as boolean,
        taskFinished: false as boolean,
        downloadLink: "" as string,
        timeoutScheduled: false as boolean,
        timeoutClear: false as boolean,
        errorMessageDisplaying: false as boolean
      };
    },
    computed: {
      ...mapState([
        'hostName'
      ])
    },
    watch: {
      execute(newValue: boolean, oldValue: boolean): void {
        if (newValue !== oldValue) {
          if (this.definition) {
            this.executeTask();
          } else {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`web-socket-status - [NO-DEFINITION] - execute()`);
            }
          }
        }
      }
    },
    methods: {
      connect(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - connect()`);
        }

        if (!WebSocketIntegrationService.active()) {
          WebSocketIntegrationService.connect(this.onConnectCallback, this.onDisconnectCallback, this.onStompErrorCallback);
        } else {
          this.subscribe();
        }
      },
      onConnectCallback(frame: IFrame): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - onConnectCallback() - ${frame.command} - ${this.status}`);
        }

        this.$emit('connect', this.definition);
        this.subscribe();
      },
      scheduleTimeoutDetection(timeout: number = this.definition.timeout): void {
        if (!this.timeoutScheduled && this.definition && this.definition.timeout > -1) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`web-socket-status - ${this.definition.webSocketTaskType} - scheduling timeout detection with interval '${this.definition.timeout}ms'`);
          }

          setTimeout(() => {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`web-socket-status - ${this.definition.webSocketTaskType} - timeout clear? '${this.timeoutClear}' - task finished? '${this.taskFinished}'`);
            }

            if (!this.taskFinished) {
              if (this.timeoutClear) {
                this.timeoutClear = false;
                this.scheduleTimeoutDetection(); // Schedule next check
              } else {
                if (process.env.NODE_ENV !== 'production') {
                  console.log(`web-socket-status - ${this.definition.webSocketTaskType} - timeout detected after '${this.definition.timeout}ms'`);
                }
                this.close();
                this.showErrorMessage();
              }
              this.timeoutScheduled = false;
            }
          }, timeout);
          this.timeoutScheduled = true;
        }
      },
      onDisconnectCallback(frame: IFrame): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - onDisconnectCallback() - ${frame.command}`);
        }
        this.updateTaskStatus(WebSocketTaskStatus.ABORTED);

        this.taskFinished = true;
        this.displayDownloadButton = false;
      },
      onStompErrorCallback(error: Event): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - onStompErrorCallback() - Reconnections ${this.reconnections} (${applicationConfiguration.properties.websocket.reconnectionLimit})`);
        }

        // Normally errors can be ignored, since the connection is kept and recovered...
        this.reconnections++;
        if (this.reconnections >= applicationConfiguration.properties.websocket.reconnectionLimit) {
          this.disconnect();
          this.showErrorMessage();
        }
      },
      subscribe(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - subscribe()`);
        }

        if (!WebSocketIntegrationService.active()) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`web-socket-status - subscribe() - connection not active, recursively calling subscribe() on connect`);
          }

          WebSocketIntegrationService.connect(this.subscribe, this.onDisconnectCallback, this.onStompErrorCallback);
        } else {
          try {
            WebSocketIntegrationService.subscribe(this.definition.destination, this.onSubscribeCallback);
          } catch (e: Error) {
            if (process.env.NODE_ENV !== 'production') {
              console.log(`web-socket-status - subscribe() - error trying to subscribe`, e);
            }
            this.showErrorMessage();
          }
        }
      },
      onSubscribeCallback(message: IFrame): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - onSubscribeCallback() - ${this.status}`);
          console.log(`message (IFrame):`);
          console.log(message);
        }

        let response: WebSocketResponse = Object.assign(new WebSocketResponse(), JSON.parse(message.body));
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - response.webSocketResponseType=${response.webSocketResponseType} - onSubscribeCallback()`);
        }

        this.updateHostName(Optional(response.hostName)(''));
        this.progressVisible = true;

        if (response.webSocketResponseType === WebSocketResponseType.SUBSCRIPTION) {
          let hostName: string = Optional(this.hostName)('');
          if (hostName !== '' && response.hostName !== hostName) {
            if (this.reconnections <= applicationConfiguration.properties.websocket.reconnectionLimit) {
              this.handleReconnection();
            } else {
              this.$bvModal.msgBoxOk(this.$t('error').toString());
            }
            return;
          }
          this.reconnections = 1;

          if (this.status === WebSocketTaskStatus.EXECUTE) {
            if (!this.statusCheck) { // No need to send anything, just connect to the backend to resume updates
              let request: WebSocketRequest = new WebSocketRequest(this.definition.webSocketTaskType, WebSocketRequestType.DESTINATION, this.definition.destination, this.payload);
              if (this.payload && this.payload.binary) {
                WebSocketIntegrationService.binaryRequest(request);
              } else {
                WebSocketIntegrationService.jsonRequest(request);
              }
            } else {
              if (process.env.NODE_ENV !== 'production') {
                console.log(`web-socket-status - Status Check - onSubscribeCallback()}`);
              }
            }
          }

          this.scheduleTimeoutDetection(this.definition.timeout * 2); // Initially longer to account for overhead...

          this.$emit('subscribe');
        } else if (response.webSocketResponseType === WebSocketResponseType.MESSAGE) {
          let payload: WebSocketMessagePayload = Object.assign(new WebSocketMessagePayload(), response.payload);

          if (payload.result === true) {
            this.$bvModal.msgBoxOk(payload.message as string);
            this.close();
          } else {
            this.progressText = payload.message;
            this.updateTaskStatus(WebSocketTaskStatus.GENERATING);
          }
        } else if (response.webSocketResponseType === WebSocketResponseType.PROGRESS) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Received progress, clearing timeout`);
          }

          this.updateTaskStatus(WebSocketTaskStatus.GENERATING);

          let payload: WebSocketProgressPayload = Object.assign(new WebSocketProgressPayload(), response.payload);
          this.progressValue = payload.value;
          this.progressMax = payload.max;

          this.$emit('progress', payload);
        } else if (response.webSocketResponseType === WebSocketResponseType.INSTRUCTION_RECEIVED) {
          let request = new WebSocketRequest(this.definition.webSocketTaskType, WebSocketRequestType.DESTINATION, this.definition.destination, this.payload);
          if (this.payload?.binary) {
            WebSocketIntegrationService.binaryRequest(request);
          } else {
            WebSocketIntegrationService.jsonRequest(request);
          }
        } else if (response.webSocketResponseType === WebSocketResponseType.LINK) {
          this.updateTaskStatus(WebSocketTaskStatus.FINISHED);

          let payload: WebSocketLinkPayload = Object.assign(new WebSocketLinkPayload(), response.payload);
          if (payload.link != null) {
            this.downloadLink = payload.link;
          }

          this.progressValue = 100;
          this.progressMax = 100;
          this.taskFinished = true;
          this.progressText = this.resetProgressText();
          if (process.env.NODE_ENV !== 'production') {
            console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Task finished from link`);
          }

          this.$emit('link', payload);

          this.unsubscribe();
        } else if (response.webSocketResponseType === WebSocketResponseType.FINISHED) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Task finished from notification`);
          }

          this.progressValue = 100;
          this.progressMax = 100;
          this.taskFinished = true;
          this.progressText = this.resetProgressText();
          this.unsubscribe();
        } else if (response.webSocketResponseType === WebSocketResponseType.ERROR) {
          this.$emit('error');
          this.handleReconnection();
          let payload: WebSocketErrorPayload = Object.assign(new WebSocketErrorPayload(), response.payload);
          if (process.env.NODE_ENV !== 'production') {
            console.log('ERROR response.payload');
            console.log(payload);
          }
          this.showErrorMessage(payload);
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`Unregistered webSocketResponseType: ${this.definition.webSocketTaskType} - onSubscribeCallback()`);
          }
        }
      },
      showErrorMessage(payload?: WebSocketErrorPayload): void {
        let message: string = 'error.default';
        let details: string = '';
        if (payload) {
          if (payload.error && !payload.exception) {
            message = payload.error;
          }

          if (payload.details) {
            details = `: ${payload.details}`;
          }
        }

        if (!this.errorMessageDisplaying) {
          this.errorMessageDisplaying = true;
          this.$bvModal.msgBoxOk(`${this.$t(message).toString()}${details}`).then(value => {
            this.errorMessageDisplaying = false;
          });
        }
      },
      resetProgressText(): String {
        return this.progressText !== this.initialProgressText ? "" : this.initialProgressText;
      },
      executeTask(): void {
        this.taskFinished = false;
        this.progressText = this.resetProgressText();
        this.updateTaskStatus(WebSocketTaskStatus.EXECUTE);

        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - executeTask() - ${this.status}`);
        }

        if (!WebSocketIntegrationService.active()) {
          WebSocketIntegrationService.connect(this.onConnectCallback, this.onDisconnectCallback, this.onStompErrorCallback);
        } else {
          this.subscribe();
        }
      },
      unsubscribe(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - unsubscribe()`);
        }

        this.$emit('unsubscribe', this.status);

        WebSocketIntegrationService.unsubscribe(this.definition.destination);
      },
      updateTaskStatus(taskStatus: WebSocketTaskStatus): void {
        this.status = taskStatus;

        this.timeoutClear = true;
        this.reconnections = 1;

        this.$emit('status-updated', this.status);

        const payload = {
          key: this.definition.webSocketTaskType,
          value: taskStatus
        };

        this.$store.commit('updateTaskStatus', payload);
      },
      handleReconnection(): void {
        if (!this.taskFinished) {
          this.unsubscribe();

          if (this.reconnections <= applicationConfiguration.properties.websocket.reconnectionLimit) {
            setTimeout(() => {
              if (!this.taskFinished && this.definition?.webSocketTaskType) {
                if (process.env.NODE_ENV !== 'production') {
                  console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Reconnection attempt ${this.reconnections}`);
                }
                this.subscribe();
              }
            }, applicationConfiguration.properties.websocket.localReconnectDelay);
          }

          this.reconnections++;
        }
      },
      updateHostName(hostName: string): void {
        this.$store.commit('updateHostName', hostName);
      },
      disconnect(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Disconnect!`);
        }
        WebSocketIntegrationService.disconnect();

        this.taskFinished = true;
        this.displayDownloadButton = false;
        this.progressVisible = false;
        this.progressValue = 0;
        this.progressMax = 100;
        this.downloadLink = '';
        this.progressText = this.resetProgressText();
        this.updateTaskStatus(WebSocketTaskStatus.IDLE);
      },
      close(): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Task finished from close`);
        }

        this.taskFinished = true;
        this.displayDownloadButton = false;
        this.progressVisible = false;
        this.progressValue = 0;
        this.progressMax = 100;
        this.downloadLink = '';
        this.progressText = this.resetProgressText();
        this.updateTaskStatus(WebSocketTaskStatus.IDLE);
        this.unsubscribe();
      },
      cancel(): void {
        this.updateTaskStatus(WebSocketTaskStatus.ABORTED);

        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - Task finished from cancel`);
        }

        this.taskFinished = true;

        let cancelPayload: WebSocketCancelTaskPayload = new WebSocketCancelTaskPayload();
        cancelPayload.addExtraData("cancel", true); // This is for binary-type of payloads

        WebSocketIntegrationService.jsonRequest(
          new WebSocketRequest(this.definition.webSocketTaskType,
                               WebSocketRequestType.DESTINATION, this.definition.destination, cancelPayload));

        if (!this.cancelResult) {
          this.close();
        }

        // Keep the connection opened waiting for a confirmation message from the backend that will close the connection
      }
    },
    created(): void {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`web-socket-status - ${this.definition.webSocketTaskType} - created()`);
      }

      this.status = Optional(this.$store.state.webSocketTaskStatuses[this.definition.webSocketTaskType])(WebSocketTaskStatus.IDLE);
      if (this.status !== WebSocketTaskStatus.IDLE && this.status !== WebSocketTaskStatus.FINISHED && this.status !== WebSocketTaskStatus.ABORTED) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-status - ${this.definition.webSocketTaskType} - ${this.status} - connecting`);
        }
        this.connect();
      }
    },
    i18n: {
      messages: messages
    }
  });
