
  import Vue, { PropType } from 'vue';
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import textDate from '@/main/webapp/vue/components/ui/text/text-date/index.vue';

  import { MapProjectDetails } from "@/main/webapp/vue/model/api/Map/MapProjectDetails";
  import TranslationUtil from "@/main/webapp/vue/util/translationUtil";

  export default Vue.extend({
    props: {
      item: {
        type: Object as PropType<MapProjectDetails>
      },
      noActivityColor: {
        type: String,
        default: "red"
      },
      showCreationDate: {
        type: Boolean,
        default: true
      }
    },
    components: {
      textDate
    },
    data() {
      return {
        thumbWidth: (this as any).$properties.content.image.size.thumbnail.width,
        thumbHeight: (this as any).$properties.content.image.size.thumbnail.height,
        imgStyle: {
          'text-align': 'center',
          'width': `${(this as any).thumbWidth}px`,
          'line-height': `${(this as any).thumbHeight}px`,
          'height': `${(this as any).thumbHeight}px`
        }
      };
    },
    methods: {
      noActivityTranslation(): string { // i1n8 is not working in popup
        return TranslationUtil.translate('text.no-activity', messages);
      }
    }
  });
