
  import Vue from 'vue';
  import inputField from "@/main/webapp/vue/components/ui/input/index.vue";

  export default Vue.extend({
    components: {
      inputField
    },
    props: {
      colorStyle: {
        type: String,
        default: 'dark'
      },
      noCaret: {
        type: Boolean,
        default: false
      },
      enableSearch: {
        type: Boolean,
        default: false
      },
      storedSearchTerm: {
        type: String,
        default: ''
      },
      itemMaxHeight: {
        type: Number
      }
    },
    data() {
      return {
        focus: false as boolean
      };
    },
    methods: {
      emitChanges(searchTerm: string): void {
        this.$emit('search-term-changed', searchTerm);
      },
      toggleFocus(show: boolean): void {
        this.focus = show;
      }
    },
    mounted(): void {
      this.emitChanges(this.storedSearchTerm);
    }
  });
