
  import Vue from 'vue';
  import { TranslateResult } from "vue-i18n";
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';
  import messages from './messages.json';

  import entityButton from "@/main/webapp/vue/components/ui/entity-button/index.vue";
  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";

  import { WebSocketTaskStatus } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskStatus";
  import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";

  export default Vue.extend({
    components: {
      loadingIcon,
      entityButton
    },
    props: {
      type: {
        type: String, // Enum passed as String? PropType<WebSocketTaskType>,
        required: true
      },
      taskStatus: {
        type: String, // Enum passed as String? PropType<WebSocketTaskStatus>,
        required: true
      },
      progressTitle: {
        type: String,
        required: true
      },
      progressText: {
        type: String,
        default: null
      },
      progressTextLong: {
        type: Boolean,
        default: false
      },
      visible: {
        type: Boolean,
        default: false
      },
      showSpinner: {
        type: Boolean,
        default: false
      },
      taskFinished: {
        type: Boolean,
        default: false
      },
      progressValue: {
        type: Number,
        default: 0
      },
      progressMax: {
        type: Number,
        default: 100
      },
      downloadLink: {
        type: String,
        required: false
      }
    },
    created(): void {
      if (this.showSpinner) {
        this.displayProgress = false;
      } else {
        this.displayProgress = true;
      }
    },
    data() {
      return {
        displayProgress: false as boolean,
        displayDownloadButton: true as boolean,
        progressAnimated: true as boolean,
        toastVisible: false as boolean,
        task: WebSocketTaskDefinition
      };
    },
    computed: {
      initializing(): boolean {
        return this.taskStatus === WebSocketTaskStatus.EXECUTE;
      },
      progressLabel(): string {
        const value: number = this.progressValue;
        const max: number = this.progressMax;
        return `${((value / max) * 100).toFixed(0)} %`;
      },
      computedTaskProgressText(): TranslateResult {
        switch (this.taskStatus) {
          case WebSocketTaskStatus.EXECUTE:
            return this.$t("text.initializing").toString();
          case WebSocketTaskStatus.GENERATING:
            return this.$t("text.processing").toString();
          case WebSocketTaskStatus.IDLE || WebSocketTaskStatus.FINISHED:
            return this.$t("text.finalizing").toString();
          default:
            return "";
        }
      }
    },
    methods: {
      close() {
        this.toastVisible = false;
        this.progressAnimated = true;
        this.displayDownloadButton = false;
        this.$emit('close');
      },
      cancel() {
        this.$emit('abort');
      }
    },
    watch: {
      taskFinished(newValue: boolean, oldValue: boolean): void {
        if (newValue !== oldValue) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`web-socket-progress - ${this.type} - watch - taskFinished - ${newValue}`);
          }

          if (newValue) {
            this.progressAnimated = false;
            this.toastVisible = true;

            if (this.downloadLink !== '') {
              this.displayDownloadButton = true;
            }
          } else {
            this.progressAnimated = true;
            this.toastVisible = false;
            this.displayDownloadButton = false;
          }
        }
      }
    },
    i18n: {
      messages: messages,
      sharedMessages: sharedMessages
    }
  });
