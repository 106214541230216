
  import Vue, { PropType } from 'vue';

  import webSocketRequest from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue";

  import { SearchCriteriaSelectedDataValue } from "@/main/webapp/vue/model/api/SearchCriteriaSelectedDataValue";
  import { ListViewSelectedField } from "@/main/webapp/vue/model/api/ListViewSelectedField";
  import { ReportSearchCriteria } from "@/main/webapp/vue/model/api/web-socket/ReportSearchCriteria";

  export default Vue.extend({
    components: {
      webSocketRequest
    },
    props: {
      type: {
        type: String, // Object as PropType<WebSocketTaskType>,
        required: true
      },
      visible: {
        type: Boolean,
        default: true
      },
      itemCount: {
        type: Number,
        default: 0
      },
      isListView: {
        type: Boolean,
        default: false
      },
      searchCriteriaSelectedDataValues: {
        type: Array as PropType<SearchCriteriaSelectedDataValue[]>,
        required: false
      },
      selectedSubmissionIds: {
        type: Array as PropType<Number[]>,
        required: false
      },
      listViewSelectedFields: {
        type: Array as PropType<ListViewSelectedField[]>,
        required: false
      }
    },
    data() {
      return {
        payload: {} as ReportSearchCriteria
      };
    },
    watch: {
      searchCriteriaSelectedDataValues(newValue: SearchCriteriaSelectedDataValue[], oldValue: SearchCriteriaSelectedDataValue[]): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-filter-request - watch - searchCriteriaSelectedDataValues`);
        }

        this.payload.selectedCriteria = newValue;

        if (process.env.NODE_ENV !== 'production') {
          console.log(this.payload);
        }
      },
      selectedSubmissionIds(newValue: Number[], oldValue: Number[]): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-filter-request - watch - selectedSubmissionIds`);
        }

        this.payload.selectedSubmissionIds = newValue;

        if (process.env.NODE_ENV !== 'production') {
          console.log(this.payload);
        }
      },
      listViewSelectedFields(newValue: ListViewSelectedField[], oldValue: ListViewSelectedField[]): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-filter-request - watch - listViewSelectedFields`);
        }

        this.payload.selectedFields = this.isListView ? newValue : null;

        if (process.env.NODE_ENV !== 'production') {
          console.log(this.payload);
        }
      },
      isListView(newValue: boolean, oldValue: boolean): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-filter-request - watch - isListView`);
        }

        if (!newValue) {
          this.payload.selectedFields = null;
        }

        if (process.env.NODE_ENV !== 'production') {
          console.log(this.payload);
        }
      }
    },
    created() {
      this.payload.selectedCriteria = this.searchCriteriaSelectedDataValues;
      this.payload.selectedSubmissionIds = this.selectedSubmissionIds;
      this.payload.selectedFields = this.isListView ? this.listViewSelectedFields : null;
    }
  });
