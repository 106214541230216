
  import Vue, { PropType } from 'vue';
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  import glyphiconSymbol from '../../glyphicon/glyphicon-symbol/index.vue';
  import textColored from '../../text/text-colored/index.vue';
  import submissionMetadataAttribute from "../submission-metadata-attribute/index.vue";
  import fullscreenCarousel from "@/main/webapp/vue/components/ui/content/carousel/fullscreen-carousel/index.vue";

  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { AttributeEntity, AttributeEntityValue } from "@/main/webapp/vue/model/api/AttributeEntity";

  export default Vue.extend({
    name: 'submissionMetadataElement',
    components: {
      fullscreenCarousel,
      glyphiconSymbol,
      textColored,
      submissionMetadataAttribute
    },
    props: {
      element: {
        type: Object as PropType<HierarchicalEntity>,
        default: null
      },
      showOnlyValue: {
        type: Boolean,
        default: false
      },
      disablePictureClickModal: {
        type: Boolean,
        default: false
      },
      parentName: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        fullscreen: {
          submission: null as Item | null,
          slideIndex: 0 as number,
          info: "" as string,
          show: false as boolean
        }
      };
    },
    computed: {
      webLink(): string | undefined {
        return this.getWebLink(this.element);
      }
    },
    methods: {
      getWebLink(element: HierarchicalEntity | undefined): string | undefined {
        if (element && element.nav) {
          return NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, element.nav)?.href;
        }
        return undefined;
      },
      handlePictureClick(value: ItemContent, slideIndex: number, attribute: AttributeEntity<AttributeEntityValue>) {
        this.$emit('picture-click', value);

        if (!this.disablePictureClickModal && attribute) {
          const tempItem: Item = new Item();
          tempItem.id = new Date().getTime();
          tempItem.contents = attribute.values as ItemContent[];

          this.fullscreen.submission = tempItem;
          this.fullscreen.slideIndex = slideIndex;
          this.fullscreen.info = `${this.parentName ? this.parentName : ''}${this.parentName ? ' / ' : ''}${this.element.name}`;

          setTimeout(() => {
            this.fullscreen.show = true;
          }, 100);
        }
      },
      onFullscreenClose(): void {
        this.fullscreen.show = false;
        this.$emit('fullscreen-carousel-close');
      }
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
