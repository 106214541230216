
  import Vue from 'vue';

  import webSocketStatus from "@/main/webapp/vue/components/web-socket/web-socket-status/index.vue";

  import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";
  import { WebSocketTaskType } from '@/main/webapp/vue/model/api/web-socket/WebSocketTaskType';
  import { WebSocketTaskDefinitions } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinitions";

  export default Vue.extend({
    name: "web-socket-status-checker",
    components: {
      webSocketStatus
    },
    computed: {
      webSocketTaskTypes(): WebSocketTaskType[] {
        return WebSocketTaskDefinitions.types();
      }
    },
    methods: {
      webSocketTaskDefinitionFromType(type: WebSocketTaskType): WebSocketTaskDefinition {
        return WebSocketTaskDefinitions.generateDefinitionWithType(type);
      }
    }
  });
