import { Item } from "@/main/webapp/vue/model/api/Item";
import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";

export default class SubmissionUtil {
  static checkIfNoSubmission(submission: Item, slideIndex: number, itemIndex: number): boolean {
    if (!submission.contents || submission.contents.length === 0) {
      this.navigateToItemPage(submission, slideIndex, itemIndex);
      return true;
    }

    return false;
  }

  static navigateToItemPage(submission: Item, slideIndex: number, itemIndex: number): boolean {
    const link: string | undefined = this.computedItemPageLink(submission, slideIndex, itemIndex);
    if (link) {
      window.location.href = link;
    }
  }

  static computedItemPageLink(submission: Item, slideIndex: number, itemIndex: number): string | undefined {
    let webLink: string | undefined;

    if (submission) {
      let nav: NavigationLinks | undefined;
      if (submission.contents && submission.contents.length > 0) {
        const content: ItemContent = submission.contents[slideIndex];
        if (content && content.nav) {
          nav = content.nav;
        }
      } else if (submission.nav) {
        nav = submission.nav;
      }

      webLink = NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, nav)?.href;
    }

    return this.addParams(webLink, itemIndex);
  }


  static addParams(webLink: string | undefined, itemIndex: number): string | undefined {
    if (webLink) {
      let params: string = "";

      if (itemIndex !== null && itemIndex !== undefined) {
        params = this.prepareParams(itemIndex);
      }

      return `${webLink}${params}`;
    }
    return undefined;
  }

  static prepareParams(itemIndex: number): string {
    let parameters = "";
    for (const urlSearchParam of new URLSearchParams(window.location.search)) {
      parameters += `&${urlSearchParam[0]}=${urlSearchParam[1]}`;
    }

    return `&iidx=${itemIndex}${parameters}`;
  }
}
