
  import Vue, { PropType } from 'vue';
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

  import carousel from "@/main/webapp/vue/components/ui/content/carousel/index.vue";
  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";

  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";
  import SubmissionUtil from "@/main/webapp/vue/util/submissionUtil";

  export default Vue.extend({
    components: {
      glyphiconSymbol,
      carousel
    },
    props: {
      submission: {
        type: Object as PropType<Item>
      },
      type: {
        type: String as PropType<ItemContentType>,
        default: ItemContentType.STANDARD
      },
      itemIndex: {
        type: Number,
        default: null
      },
      slideIndex: {
        type: Number,
        default: 0
      },
      additionalInfo: {
        type: String,
        default: ''
      },
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        currentSlideIndex: this.slideIndex as number
      };
    },
    computed: {
      computedItemPageLink(): string | undefined {
        return SubmissionUtil.computedItemPageLink(this.submission, this.currentSlideIndex, this.itemIndex);
      }
    },
    methods: {
      emitPreviousIndex(): void {
        this.emitIndex(this.itemIndex - 1);
      },
      emitNextIndex(): void {
        this.emitIndex(this.itemIndex + 1);
      },
      emitIndex(newIndex: number): void {
        this.$emit('item-index-changed', newIndex);
      },
      onSlideIndexChange(newIndex: number): void {
        this.currentSlideIndex = newIndex;
      },
      onClose(): void {
        this.$emit('close');
      }
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
