
  import Vue, { PropType } from 'vue';
  import { mapState } from 'vuex';

  import { DateTime } from "@/main/webapp/vue/model/api/DateTime";

  import TimeUtil from '@/main/webapp/vue/util/timeUtil';
  import { DateTimeIso8601 } from "@/main/webapp/vue/model/api/DateTimeIso8601";

  export default Vue.extend({
    props: {
      date: {
        type: Object as PropType<DateTime>,
        required: true
      },
      displayField: {
        type: String,
        default: "timestamp"
      },
      relative: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        relativeDateMaxHours: -12 as number
      };
    },
    computed: {
      ...mapState([
        'properties'
      ]),
      computedDate(): string {
        return this.convertToLocalTime(this.date);
      }
    },
    methods: {
      convertToLocalTime(date: DateTime): string {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`text-date value: ${date.value}`);
        }

        if (date.value) { // If date in ISO-8601 is present, then use it instead of the legacy ones
          return this.convertIso8601(date);
        }

        return this.convertLegacy(date);
      },
      convertIso8601(timestamp: DateTimeIso8601): string {
        if (this.relative && TimeUtil.isAfterHours(timestamp, this.relativeDateMaxHours)) {
          return TimeUtil.formatIso8601Relative(timestamp);
        }

        let timestampFormat = (this as any).$properties.timestampFormat;
        return TimeUtil.formatIso8601(timestamp, timestampFormat.display[this.displayField]);
      },
      convertLegacy(date: DateTime): string {
        let diffHours: number = TimeUtil.getTimeDifference(date, 'hours');
        if (this.relative && (diffHours > this.relativeDateMaxHours)) {
          return TimeUtil.convertToRelativeTime(date);
        }

        let timestampFormat = (this as any).$properties.timestampFormat;
        return TimeUtil.formatToDateString(date, timestampFormat.display[this.displayField]);
      }
    }
  });
