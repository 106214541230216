
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";
  import glyphiconFiletype from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-filetype/index.vue';
  import glyphiconSymbol from '@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue';
  import textSpan from '@/main/webapp/vue/components/ui/text/text-span/index.vue';
  import webSocketStatus from "@/main/webapp/vue/components/web-socket/web-socket-status/index.vue";
  import { BButton, BDropdownItemButton } from "bootstrap-vue";

  import { WebSocketTaskStatus } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskStatus";
  import { WebSocketTaskDefinition } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinition";
  import { WebSocketTaskPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskPayload";
  import { WebSocketTaskDefinitions } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskDefinitions";

  export default Vue.extend({
    components: {
      loadingIcon,
      glyphiconFiletype,
      glyphiconSymbol,
      textSpan,
      webSocketStatus,
      BDropdownItemButton,
      BButton
    },
    props: {
      type: {
        type: String, // Object as PropType<WebSocketTaskType>,
        required: true
      },
      itemCount: {
        type: Number,
        default: 0
      },
      showSpinner: {
        type: Boolean,
        default: false
      },
      cancelResult: {
        type: Boolean,
        default: false
      },
      selectedSubmissionIds: {
        type: Array as PropType<Number[]>,
        required: false
      },
      payload: {
        type: Object as PropType<WebSocketTaskPayload>,
        required: false
      },
      standalone: {
        type: Boolean,
        default: false
      },
      buttonVariant: {
        type: String,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      },
      beforeExecution: {
        type: Function,
        default: null
      },
      afterExecution: {
        type: Function,
        default: null
      },
      label: {
        type: String,
        default: ""
      },
      initialProgressText: {
        type: String,
        default: ""
      },
      progressTextLong: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        executeTask: false as boolean,
        statusCheck: true as boolean,
        executeButtonDisabled: false as boolean,
        status: WebSocketTaskStatus.IDLE as WebSocketTaskStatus,
        dataDefinition: WebSocketTaskDefinitions.generateDefinitionWithTypeString(this.type) as WebSocketTaskDefinition,
        displaySpinner: false as boolean,
        displayCheck: false as boolean
      };
    },
    computed: {
      componentType() {
        return this.standalone ? BButton : BDropdownItemButton;
      }
    },
    methods: {
      connect(task: WebSocketTaskDefinition) {
        this.executeButtonDisabled = false;
      },
      subscribe() {
        this.executeButtonDisabled = true;
        this.statusCheck = true; // Need to reset to avoid sending initial requests on updates
      },
      unsubscribe() {
        this.executeButtonDisabled = false;
        this.statusCheck = true; // Need to reset to avoid sending initial requests on updates
        this.$emit("finished");
      },
      checkRestrictionsAndExecuteTask(): void {
        if (this.dataDefinition && this.dataDefinition.maxItemCondition > -1) {
          let maxItemRestrictionEnabled = true;
          let selectedSubmissionsEnabled: boolean = this.selectedSubmissionIds && this.selectedSubmissionIds.length > 0;
          let selectedSubmissionCountOverMaxItemCondition: boolean = (this.selectedSubmissionIds && this.selectedSubmissionIds.length > 0) && (this.selectedSubmissionIds && this.selectedSubmissionIds.length > this.itemCount);
          let itemCountOverMaxItemCondition: boolean = this.itemCount > this.dataDefinition.maxItemCondition;

          if (maxItemRestrictionEnabled && ((selectedSubmissionsEnabled && selectedSubmissionCountOverMaxItemCondition) || (!selectedSubmissionsEnabled && itemCountOverMaxItemCondition))) {
            this.$bvModal.msgBoxOk(this.$t('confirm-text', { 'item-count': this.dataDefinition.maxItemCondition }).toString());
          } else {
            this.callBeforeExecutionAndExecute();
          }
        } else {
          this.callBeforeExecutionAndExecute();
        }
      },
      callBeforeExecutionAndExecute(): void {
        if (this.beforeExecution !== null) {
          let result: any = this.beforeExecution(); // It may be that the function returns a Promise, or not, depends on the parent component

          Promise.resolve(result).then(() => { // if the Promise resolves, we execute the task
            if (process.env.NODE_ENV !== 'production') {
              console.log(`web-socket-request component - ${this.dataDefinition.webSocketTaskType} - callBeforeExecutionAndExecute() - result: `, result);
            }

            this.statusCheck = false;
            this.executeTask = !this.executeTask;
          });
        } else {
          this.statusCheck = false;
          this.executeTask = !this.executeTask;
        }
      },
      callAfterExecution(): void {
        if (this.afterExecution !== null) {
          let result: any = this.afterExecution(); // It may be that the function returns a Promise, or not, depends on the parent component

          Promise.resolve(result).then(() => { // if the Promise resolves, we execute the task
            if (process.env.NODE_ENV !== 'production') {
              console.log(`web-socket-request component - ${this.dataDefinition.webSocketTaskType} - callAfterExecution() - result: `, result);
            }
          });
        }
      },
      statusUpdated(taskStatus: WebSocketTaskStatus): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`web-socket-request component - ${this.dataDefinition.webSocketTaskType} - statusUpdated() - ${taskStatus}`);
        }

        switch (taskStatus) {
          case WebSocketTaskStatus.IDLE:
            this.displaySpinner = false;
            this.displayCheck = false;
            break;
          case WebSocketTaskStatus.GENERATING:
            this.displaySpinner = true;
            this.displayCheck = false;
            break;
          case WebSocketTaskStatus.FINISHED:
            this.displaySpinner = false;
            this.displayCheck = true;
            this.callAfterExecution();
            break;
          case WebSocketTaskStatus.ABORTED:
            this.displaySpinner = false;
            this.displayCheck = false;
            this.callAfterExecution();
            break;
        }

        this.status = taskStatus;
      },
      close() {
        this.executeButtonDisabled = false;
      }
    },
    i18n: {
      messages: messages
    }
  });
