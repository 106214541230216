
  import Vue from 'vue';

  import dataTableLazy from '@/main/webapp/vue/components/ui/table/lazy/index.vue';
  import { ActionType, Field, FieldType, SortDirection } from '@/main/webapp/vue/components/ui/table/data/index.vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { ShopDetailsWithMetadata } from "@/main/webapp/vue/model/api/ShopDetailsWithMetadata";
  import { Address } from "@/main/webapp/vue/model/api/Address";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";

  export enum FieldKey {
    SHOP_NAME = "name",
    CHAIN_NAME = "chainName",
    EXTERNAL_ID = "externalId",
    CREATION_TIMESTAMP = "created",
    ADDRESS = "address",
    PROVIDER = "provider",
    STATUS = "active",
    ACTIONS = "actions"
  }

  export default Vue.extend({
    components: {
      dataTableLazy
    },
    data() {
      return {
        sortBy: FieldKey.SHOP_NAME as FieldKey,
        sortDesc: false as boolean,
        fields: [
          {
            key: "id",
            label: "ID",
            type: FieldType.TEXT,
            active: false
          },
          {
            key: FieldKey.SHOP_NAME,
            label: FieldKey.SHOP_NAME,
            type: FieldType.TEXT,
            active: true
          },
          {
            key: FieldKey.CHAIN_NAME,
            label: FieldKey.CHAIN_NAME,
            type: FieldType.TEXT,
            active: true
          },
          {
            key: FieldKey.EXTERNAL_ID,
            label: FieldKey.EXTERNAL_ID,
            type: FieldType.TEXT,
            active: true
          },
          {
            key: FieldKey.PROVIDER,
            label: FieldKey.PROVIDER,
            type: FieldType.TEXT,
            active: false
          },
          {
            key: FieldKey.ADDRESS,
            label: FieldKey.ADDRESS,
            type: FieldType.TEXT,
            active: true,
            formatter: (value: Address, key: any, item: any): string => {
              return value != null ? `${value.street}, ${value.postalCode} ${value.city}` : '';
            }
          },
          {
            key: "location.latitude",
            label: "latitude",
            type: FieldType.TEXT,
            group: "location",
            active: false
          },
          {
            key: "location.longitude",
            label: "longitude",
            type: FieldType.TEXT,
            group: "location",
            active: false
          },
          {
            key: FieldKey.CREATION_TIMESTAMP,
            type: FieldType.TIMESTAMP_LEGACY,
            active: true
          },
          {
            key: FieldKey.STATUS,
            label: "status",
            type: FieldType.TEXT,
            active: true,
            formatter: (value: Boolean, key: any, item: any): string => {
              return value !== null && value === true ? 'Active' : 'Inactive';
            }
          },
          {
            key: FieldKey.ACTIONS,
            type: FieldType.ACTIONS,
            actions: [
              {
                type: ActionType.INFO_MODAL,
                icon: {
                  title: "Show metadata"
                },
                modal: {
                  title: (item: ShopDetailsWithMetadata) => { return item.name; },
                  contentProvider: (item: ShopDetailsWithMetadata) => {
                    return new Promise<String>((resolve, reject) => {
                      BackendIntegrationService.fetchShopDetails(item).then((shopDetails: ShopDetailsWithMetadata) => {
                        resolve(JSON.stringify(shopDetails, null, 2));
                      }).catch((error: BackendError) => {
                        reject(error);
                      });
                    });
                  },
                  bgStyle: 'dark',
                  fgStyle: 'light'
                }
              }
            ]
          }
        ] as Field[]
      };
    },
    computed: {
      shopsNavigationLink(): NavigationLink {
        return NavigationLink.weblink(BackendIntegrationService.SHOPS_WITH_DETAILS);
      },
      searchNavigationLink(): NavigationLink {
        return NavigationLink.weblink(BackendIntegrationService.SHOPS_WITH_DETAILS_SEARCH);
      }
    },
    methods: {
      shopSelected(shop: ShopDetailsWithMetadata): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log("Shop selected:");
          console.log(shop);
        }
      }
    }
  });
