
  import Vue, { PropType } from 'vue';

  import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

  export default Vue.extend({
    props: {
      content: {
        type: Object as PropType<ItemContent>,
        required: true
      }
    },
    methods: {
      getWebLink(): string | undefined {
        if (this.content && this.content.nav) {
          return NavigationLinks.getLinkFromNavigation(NavigationLinkType.WEB, this.content.nav)?.href;
        }
        return '';
      }
    }
  });
