
  import Vue from 'vue';
  import { mapState } from 'vuex';
  import TimeUtil from "@/main/webapp/vue/util/timeUtil";

  export default Vue.extend({
    props: {
      date: {
        type: String,
        default: null
      },
      displayField: {
        type: String,
        default: "timestamp"
      },
      fallbackText: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        formattedDate: "" as string
      };
    },
    methods: {
      convertToLocalTime(utcDate: string): string {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`date-localized value: ${utcDate}`);
        }

        let timestampFormat = (this as any).$properties.timestampFormat;

        return TimeUtil.convertUTCToTimezoneWithLocale(utcDate, this.userDetails.timeZone, this.userDetails.locale, timestampFormat.display[this.displayField]);
      }
    },
    computed: {
      ...mapState([
        'userDetails',
        'properties'
      ]),
      computedDate(): string {
        return this.convertToLocalTime(this.date);
      }
    }
  });
