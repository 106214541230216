
  import Vue, { PropType } from 'vue';
  import messages from './messages.json';

  import feedHeaderThumb from "@/main/webapp/vue/components/feed/feed-header-thumb/index.vue";
  import collapsiblePreview from "@/main/webapp/vue/components/ui/collapsible/collapsible-preview/index.vue";
  import submissionMetadata from '@/main/webapp/vue/components/ui/submission/submission-metadata/index.vue';
  import likeContainer from "@/main/webapp/vue/components/ui/like/like-container/index.vue";
  import commentContainer from "@/main/webapp/vue/components/ui/comment/comment-container/index.vue";
  import shareContainer from "@/main/webapp/vue/components/ui/share/share-container/index.vue";
  import infoContainer from "@/main/webapp/vue/components/ui/info/info-container/index.vue";
  import contentToFullscreen from "@/main/webapp/vue/components/ui/content/contentToFullscreen/index.vue";

  import { Item } from "@/main/webapp/vue/model/api/Item";

  export default Vue.extend({
    components: {
      feedHeaderThumb,
      collapsiblePreview,
      submissionMetadata,
      likeContainer,
      commentContainer,
      shareContainer,
      infoContainer,
      contentToFullscreen
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      selectedSubmissionIds: {
        type: Array as PropType<number[]>,
        default: () => []
      },
      itemIndex: {
        type: Number
      },
      extendMode: {
        type: Boolean,
        default: false
      },
      enableSelect: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        holdMouseLeave: false as boolean,
        showMetadata: this.extendMode as boolean,
        showMore: false as boolean,
        selected: false as boolean,
        busy: false as boolean,
        slideIndex: 0 as number
      };
    },
    watch: {
      selectedSubmissionIds(newIds: number[], oldIds: number[]): void {
        this.checkIfSelected(newIds);
      }
    },
    methods: {
      checkIfSelected(newIds: number[] = []): void {
        if (this.submission) {
          this.selected = newIds.some((newId: number) => newId === this.submission.id);
        }
      },
      emitChanges(): void {
        if (this.submission && !this.busy) {
          this.busy = true;

          setTimeout(() => { // The moment of click, this.selected not changed yet
            if (this.selected) {
              this.$emit('item-selected', this.submission.id);
            } else {
              this.$emit('item-unselected', this.submission.id);
            }

            this.busy = false;
          }, 100);
        }
      },
      onItemShared(): void {
        this.selected = true;
        this.emitChanges();
      },
      showCardMetadata(): void {
        if (!this.extendMode) {
          this.showMetadata = true;
        }
      },
      hideCardMetadata(): void {
        if (!this.extendMode) {
          this.showMetadata = false;

          if (this.holdMouseLeave) {
            this.holdMouseLeave = false;
          }
        }
      },
      enableHoldMouseLeave(): void {
        this.holdMouseLeave = true;
      },
      onMouseLeave(): void {
        if (!this.holdMouseLeave) {
          this.hideCardMetadata();
        }
      },
      updateSlideIndexChange(slideIndex: number): void {
        this.slideIndex = slideIndex;
      }
    },
    mounted() {
      this.checkIfSelected(this.selectedSubmissionIds);
    },
    i18n: {
      messages: messages
    }
  });
