
  import Vue, { PropType } from 'vue';

  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  export default Vue.extend({
    props: {
      label: {
        type: String,
        default: null
      },
      size: {
        type: String,
        default: "md"
      },
      items: {
        type: Array,
        default: () => []
      },
      itemLabel: {
        type: Function,
        default: (item: any) => item.label ? item.label : item
      },
      initialSelectedItems: {
        type: Array as PropType<any[]>,
        default: () => []
      },
      placeholder: {
        type: String,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      compact: {
        type: Boolean,
        default: false
      },
      clearSelectionLabel: {
        type: String,
        default: null
      },
      showInvalidMessage: {
        type: Boolean,
        default: false
      },
      checkInvalid: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selectedItems: [] as any[],
        show: false as boolean,
        searchPhrase: '' as string,
        selectListOptionsWidth: 200 as number,
        selectListInput: null as HTMLInputElement | null
      };
    },
    watch: {
      selectedItemIds(): void {
        this.initSelectedItemsFromIds();
      }
    },
    computed: {
      computedPlaceholder(): string {
        if (this.placeholder) {
          return this.placeholder;
        }
        return `${this.$tc('generic.select', (this.multiple ? 1 : 0), { object: this.$tc('generic.value', 0).toLowerCase() })}`;
      },
      computedUnselectedItems(): any[] {
        return this.items.filter((item: any) => !this.isSelected(item));
      },
      computedItems(): any[] {
        if (this.searchPhrase && this.searchPhrase.length > 0) {
          return this.computedUnselectedItems.filter((item: any) =>
            item && this.itemLabel(item) ? this.searchHit(this.itemLabel(item)) : this.searchHit(item));
        }
        return this.computedUnselectedItems;
      }
    },
    methods: {
      searchHit(item: string): boolean {
        return item.toLowerCase().includes(this.searchPhrase.toLowerCase());
      },
      showOptions(): void {
        this.calculateWidth();
        this.show = true;
      },
      hideOptions(): void {
        setTimeout(() => {
          this.show = false;
          this.searchPhrase = "";
        }, 200);
      },
      emitChanges(): void {
        this.$emit('selected', this.multiple ? this.selectedItems : this.selectedItems[0]);
      },
      addItem(item: any): void {
        if (this.multiple) {
          this.selectedItems.push(item);
        } else {
          this.selectedItems = [item];
          this.hideOptions();
        }

        this.emitChanges();
      },
      removeItem(item: any, index: number): void {
        this.selectedItems.splice(index, 1);
        this.emitChanges();
      },
      findIndex(item: any): number {
        return this.selectedItems.findIndex((selectedItem: any) => (selectedItem.id ? (selectedItem.id === item.id) : (selectedItem === item)));
      },
      isSelected(item: any): boolean {
        return this.findIndex(item) > -1;
      },
      clearSelected(): void {
        this.selectedItems = [];
        this.$emit("cleared");
      },
      itemSelected(item: any): void {
        const index: number = this.findIndex(item);

        if (index > -1) { // already selected
          if (this.multiple) {
            this.removeItem(item, index);
          }
        } else {
          if (!this.multiple) {
            this.clearSelected();
          }

          this.addItem(item);
        }
      },
      calculateWidth(): void {
        if (this.selectListInput) {
          this.selectListOptionsWidth = this.selectListInput.clientWidth;
        }
      },
      initSelectedItemsFromIds(): void {
        this.selectedItems = this.items.filter((item: any) =>
          this.initialSelectedItems.some((selectedItem: any) => (item.id ? (selectedItem === item.id) : (selectedItem === item))));
      }
    },
    mounted(): void {
      this.selectListInput = this.$refs["select-list-input"] as HTMLInputElement;
      this.calculateWidth();
    },
    created(): void {
      this.initSelectedItemsFromIds();
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
