
  import Vue, { PropType } from 'vue';
  import Confirmation, { msgBoxType } from "@/main/webapp/vue/components/ui/modal/confirmation/index.vue";

  import { BTable, BvTableField } from "bootstrap-vue/src/components/table";
  import dateText from '@/main/webapp/vue/components/ui/text/text-date/index.vue';
  import dateLocalized from '@/main/webapp/vue/components/ui/text/text-date/localized/index.vue';
  import glyphiconAction from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-action/index.vue";
  import contentContainer from "@/main/webapp/vue/components/ui/content/content-container/index.vue";
  import contentToFullscreen from "@/main/webapp/vue/components/ui/content/contentToFullscreen/index.vue";
  import submissionMetadata from "@/main/webapp/vue/components/ui/submission/submission-metadata/index.vue";
  import submissionMetadataElement
    from "@/main/webapp/vue/components/ui/submission/submission-metadata-element/index.vue";
  import horizontalScrollClone from "@/main/webapp/vue/components/ui/scroll/horizontal-scroll-clone/index.vue";
  import scrollToTop from "@/main/webapp/vue/components/ui/scroll/scroll-to-top/index.vue";
  import selectList from "@/main/webapp/vue/components/ui/select/select-list/index.vue";

  export enum FieldType {
    TEXT = 'text',
    COMPONENT = 'component',
    NUMBER = 'number',
    SELECT_LIST = 'select_list',
    ACTIONS = 'actions',
    POPOVER = 'popover',
    TIMESTAMP = 'timestamp',
    TIMESTAMP_LEGACY = 'timestamp-legacy',
  }

  export enum TableComponent {
    CONTENT_CONTAINER = "content-container",
    CONTENT_TO_FULLSCREEN = "content-to-fullscreen",
    SUBMISSION_METADATA = "submission-metadata",
    SUBMISSION_METADATA_ELEMENT = "submission-metadata-element"
  }

  /* eslint-disable */
  export class TablePopover {
    constructor(public icon: string | Function,
                public iconLink: Function | undefined,
                public placement: string,
                public wide: boolean | undefined,
                public trigger: string | undefined,
                public component: TableComponent | undefined,
                public content: string | Function | undefined) {}
  }

  export class FieldBasic {
    constructor(public key: string,
                public path: string | undefined,
                public type: FieldType,
                public entityType: string | undefined,
                public entityId: string | undefined,
                public active: boolean | undefined,
                public priority: number = 0,
                public component: TableComponent | undefined,
                public popover: TablePopover | undefined,
                public content: Function | any | undefined,
                public group: string = '',
                public labelHidden: boolean = false) {}
  }

  export type Field = FieldBasic & BvTableField;

  class FieldGroup {
    constructor(public key: string,
                public label: string,
                public fieldCount: number) {}
  }
  /* eslint-enable */

  export enum ActionType {
    INFO_MODAL,
    INLINE_EDIT,
    REMOVE,
    CUSTOM_OPERATION
  }

  export enum SortDirection {
    ASC = "asc",
    DESC = "desc"
  }

  export default Vue.extend({
    components: {
      horizontalScrollClone,
      scrollToTop,
      dateText,
      dateLocalized,
      glyphiconAction,
      contentContainer,
      submissionMetadata,
      submissionMetadataElement,
      selectList,
      contentToFullscreen
    },
    props: {
      show: {
        type: Boolean,
        default: true
      },
      data: {
        type: Array,
        required: true
      },
      fields: {
        type: Array as PropType<Field[]>,
        required: true
      },
      uniqueKeyField: {
        type: String,
        default: null
      },
      enableFieldSelector: {
        type: Boolean,
        default: false
      },
      enableRowSelector: {
        type: Boolean,
        default: false
      },
      enableAdd: {
        type: Boolean,
        default: false
      },
      enableActions: {
        type: Boolean,
        default: true
      },
      emptyText: {
        type: String,
        default: null
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      loadingText: {
        type: String,
        default: null
      },
      loadingError: {
        type: String,
        default: null
      },
      noMarginBottom: {
        type: Boolean,
        default: false
      },
      selectedItemIds: {
        type: Array as PropType<number[]>,
        default: () => []
      },
      newRowDataType: {
        type: Function,
        default: null
      }
    },
    data() {
      return {
        editingRowIndex: null as number | null,
        selectedRowIndex: null as number | null,
        highlightFieldSelector: false as boolean,
        resizeScroll: false as boolean,
        enableEmitRowSelected: true as boolean,
        editingRowValidations: {} as any,
        editingRowOriginalValues: {} as any,
        checkInvalid: false as boolean,
        infoModal: {
          id: 'table-action-modal',
          title: '',
          content: '',
          bgStyle: '',
          fgStyle: ''
        },
        infoPopover: {
          target: '',
          title: '',
          placement: '',
          content: '',
          component: null,
          wide: false,
          clearQue: 0,
          itemIndex: null
        },
        fieldType: FieldType
      };
    },
    watch: {
      selectedItemIds(newIds: number[], oldIds: number[]): void {
        this.enableEmitRowSelected = false;
        this.setSelectedRows(newIds);
      },
      data(newData: any[], oldData: any[]) {
        this.enableEmitRowSelected = false;

        setTimeout(() => { // Delay for rendering
          if (newData.length > 0) {
            this.setSelectedRows(this.selectedItemIds);
            this.updateScrollSize(); // When new data, update scroll size
          }
        }, 500);
      }
    },
    computed: {
      sortedFields(): Field[] {
        return [...this.fields].sort((a, b) => a.priority - b.priority);
      },
      activeFields(): Field[] {
        let activeFields: Field[] =
          this.sortedFields
            .filter((field: Field) => field.active === undefined || field.active)
            .map((field: Field) => this.preProcessField(field));
        if (this.enableFieldSelector || this.enableRowSelector) {
          activeFields.unshift({
            key: "table_selector",
            active: true,
            stickyColumn: true,
            group: '-'
          } as Field);
        }

        return activeFields;
      },
      fieldGroups(): FieldGroup[] {
        return this.extractGroupsFromFields();
      }
    },
    methods: {
      preProcessField(field: Field): Field {
        switch (field.type) {
          case FieldType.TIMESTAMP : field.tdClass = `${field.class || "timestamp"}`; break;
          case FieldType.TIMESTAMP_LEGACY : field.tdClass = `${field.class || "timestamp"}`; break;
          case FieldType.POPOVER : field.tdClass = `${field.class || "center"}`; break;
        }
        return field;
      },
      setSelectedRows(newIds: number[]): void {
        if (this.enableRowSelector) {
          const dataTable: BTable = this.getComponentReference();
          if (dataTable) {
            dataTable.clearSelected();
            if (newIds && newIds.length > 0) {
              this.data.forEach((data, index) => {
                if (data && data.id && newIds.some((id: number) => id === data.id)) {
                  dataTable.selectRow(index);
                }
              });
            }
            // To keep selected since table clear selected on sorting/filtering/paginating
            setTimeout(() => {
              this.enableEmitRowSelected = true;
            }, 100);
          }
        }
      },
      updateScrollSize(): void {
        this.resizeScroll = !this.resizeScroll;
      },
      handleAction(field: Field, action: any, data: any): void {
        switch (action.type) {
          case ActionType.INFO_MODAL :
            this.showInfoModal(field, action, data.item);
            break;
          case ActionType.INLINE_EDIT :
            this.editInlineRow(data.index, data.item);
            break;
          case ActionType.REMOVE :
            this.removeRow(action, data.index, data.item);
            break;
          case ActionType.CUSTOM_OPERATION :
            break;
        }

        if (action.callback) {
          action.callback(data.item);
        }
      },
      editInlineRow(index: number, item: any): void {
        if (this.hasInvalidValue()) {
          return;
        }

        this.editingRowOriginalValues = JSON.parse(JSON.stringify(item));
        this.editingRowIndex = index;
      },
      clearEditingRowData(): void {
        this.editingRowIndex = null;
        this.editingRowValidations = {};
        this.editingRowOriginalValues = {};
      },
      cancelInlineEditing(index: number, item: any): void {
        if (index === 0 && Object.keys(this.editingRowOriginalValues).length === 0) {
          this.data.shift();
        }

        if (item) { // Revert previous saved value
          this.setSelectedItem(item, this.editingRowOriginalValues);
        }

        (this as any).$emit('edit', item, this.data);
        this.clearEditingRowData();
      },
      hasInvalidValue(): boolean {
        const hasInvalidValue: boolean = Object.keys(this.editingRowValidations).some((key: string) => this.editingRowValidations[key] === false);
        if (hasInvalidValue && !this.checkInvalid) {
          this.checkInvalid = true;
          setTimeout(() => {
            this.checkInvalid = false;
          }, 1000);
        }
        return hasInvalidValue;
      },
      addRow(): void {
        if (this.hasInvalidValue()) {
          return;
        }
        this.clearEditingRowData();

        let newRow: any;
        if (this.newRowDataType) {
          newRow = this.newRowDataType();
        } else {
          newRow = this.fields.reduce((a: any, c: any) => ({ ...a as {}, [c.key]: null }), {});
        }

        this.data.unshift(newRow);
        this.editingRowIndex = 0;
      },
      saveRow(index: number, item: any): void {
        if (this.hasInvalidValue()) {
          return;
        }
        this.clearEditingRowData();

        if (index === 0 && !item.id) {
          (this as any).$emit('add', item, this.data);
        } else {
          (this as any).$emit('edit', item, this.data);
        }
      },
      confirmation(boxType: msgBoxType = msgBoxType.CONFIRM, title: string, callback: Function | null = null): void {
        let confirmation: Vue = new Confirmation({
          propsData: {
            boxType: boxType,
            title: title
          }
        }).$mount();

        confirmation.$on('user-confirm', (confirm: boolean) => {
          if (confirm && callback) {
            callback();
          }
        });
      },
      removeRow(action: any, index: number, item: any): void {
        if (action.confirmation === undefined) {
          this.removeFromData(item, index);
        } else {
          this.confirmation(msgBoxType.CONFIRM, action.confirmation(item), () => {
            this.removeFromData(item, index);
          });
        }
      },
      removeFromData(item: any, index: number): void {
        this.data.splice(index, 1);
        (this as any).$emit('remove', item, this.data);
        this.clearEditingRowData();
      },
      validateState(field: any, data: any): boolean | null {
        let validation: boolean | null = null;
        if (field && field.validateState) {
          validation = field.validateState(data.item, data.value);
        }

        this.editingRowValidations[field.label] = validation;
        return validation;
      },
      getActionIcon(action: any): string {
        if (action.icon.symbol) {
          return action.icon.symbol; // If defined it overrides
        }

        switch (action.type) {
          case ActionType.INFO_MODAL :
            return 'info-sign';
          case ActionType.INLINE_EDIT :
            return 'edit';
          case ActionType.REMOVE :
            return 'bin';
        }

        return 'question-mark';
      },
      getActionIconColor(action: any): string {
        if (action.icon.color) {
          return action.icon.color; // If defined it overrides
        }

        switch (action.type) {
          case ActionType.INFO_MODAL :
            return 'black';
          case ActionType.INLINE_EDIT :
            return 'blue';
          case ActionType.REMOVE :
            return 'red';
        }

        return 'black';
      },
      setSelectedItem(target: any, selectedItem: any) {
        Object.keys(selectedItem).forEach((key: string) => {
          target[key] = selectedItem[key];
        });
      },
      selectListUpdated(selectedItem: any, data: any): void {
        this.setSelectedItem(data.item, selectedItem);
        this.$emit('edit', selectedItem, this.data); // Update selectedItemIds to parent
      },
      changeField(field: Field): void {
        (this as any).$emit("field-status-changed", field);
        this.updateScrollSize(); // When fields changed, update scroll size
      },
      disableField(field: Field): void {
        field.active = false;
        this.highlightFieldSelector = true;
        setTimeout(() => {
          this.highlightFieldSelector = false;
        }, 2000);
        this.changeField(field);
      },
      handleDynamicComponentProps(field: Field, data: any): PropType<any> {
        if (field.content) {
          if (field.content instanceof Function) {
            return field.content(field.path ? data.item[field.path] : data.value);
          } else {
            return field.content;
          }
        }
        return data.value;
      },
      getPopoverIcon(icon: string | Function, data: any): string {
        if (icon) {
          if (icon instanceof Function) {
            return icon(data);
          }
          return icon;
        }
        return 'info-sign';
      },
      handlePopoverAction(action: string, target: string, popover?: any, data: any): void {
        if (action === "click") {
          if (popover.trigger && popover.trigger === 'hover') {
            if (popover.iconLink) {
              popover.iconLink(data);
            }
            return;
          }
          this.toggleInfoPopover(target, popover, data);
        } else if (action === "hover") {
          if (popover.trigger && popover.trigger === 'click') {
            return;
          }
          this.showInfoPopover(target, popover, data);
        } else {
          if (popover.trigger && popover.trigger === 'click') {
            return;
          }
          this.hideInfoPopover(target, popover, data);
        }
      },
      handlePopoverHover(target: string, popover: any, data: any): void {
        if (popover.trigger && popover.trigger === 'click') {
          return;
        }
        this.showInfoPopover(target, popover, data);
      },
      toggleInfoPopover(target: string, popover: any, data: any): void {
        if (this.infoPopover.target === target) {
          this.hideInfoPopover(target, popover, data);
        } else {
          if (this.infoPopover.target === '') {
            this.showInfoPopover(target, popover, data);
          } else {
            this.hideInfoPopover(this.infoPopover.target, popover, data).then(() => {
              this.showInfoPopover(target, popover, data);
            });
          }
        }
      },
      showInfoPopover(target: string, popover: any, data: any): Promise<void> {
        if (this.infoPopover.target) {
          clearTimeout(this.infoPopover.clearQue);

          if (target !== this.infoPopover.target) {
            this.hideInfoPopover(this.infoPopover.target, popover, data, true).then(() => {
              this.showInfoPopover(target, popover, data);
            });
          }
        } else {
          this.infoPopover.target = target;
          this.infoPopover.title = popover.title;
          this.infoPopover.content = popover.content ? (popover.content instanceof Function) ? popover.content(data.value, data.item) : popover.content : data.value;
          this.infoPopover.component = popover.component;
          this.infoPopover.placement = popover.placement ? popover.placement : 'top';
          this.infoPopover.wide = popover.wide;
          this.infoPopover.clearQue = 0;
          this.infoPopover.itemIndex = data.index;

          return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
              this.$root.$emit('bv::show::popover', target);
              resolve();
            }, 50);
          });
        }
      },
      hideInfoPopover(target: string, popover: any, data: any, enforce: boolean = false): Promise<void> {
        let delayTime: number = 500;
        if (enforce) {
          delayTime = 50;
        }

        return new Promise<void>((resolve, reject) => {
          this.infoPopover.clearQue = setTimeout(() => {
            this.infoPopover.target = '';
            this.infoPopover.title = '';
            this.infoPopover.content = '';
            this.infoPopover.component = null;
            this.infoPopover.wide = false;
            this.infoPopover.itemIndex = null;

            this.$root.$emit('bv::hide::popover', target);
            resolve();
          }, delayTime);
        });
      },
      showInfoModal(field: Field, action: any, item: any): void {
        this.infoModal.title = action.modal.title(item);
        if (action.modal.contentProvider) {
          action.modal.contentProvider(item).then((content: any) => {
            this.infoModal.content = content;
          }).catch((error: any) => {
            this.infoModal.content = error;
          });
        } else {
          this.infoModal.content = action.modal.content(item);
        }
        this.infoModal.bgStyle = action.modal.bgStyle;
        this.infoModal.fgStyle = action.modal.fgStyle;
        this.$root.$emit('bv::show::modal', this.infoModal.id);
      },
      resetInfoModal(): void {
        this.infoModal.title = '';
        this.infoModal.content = '';
        this.infoModal.bgStyle = '';
        this.infoModal.fgStyle = '';
      },
      getComponentReference(): any {
        return this.$refs["data-table"];
      },
      getComponentBodyReference(): any {
        const dataTableRef: any = this.getComponentReference();
        if (dataTableRef) {
          return dataTableRef.$el;
        }

        return undefined;
      },
      registerScrollListener(): void {
        const dataTableBody = this.getComponentBodyReference();
        if (dataTableBody) {
          dataTableBody.addEventListener("scroll", this.onScroll);
        }
      },
      unregisterScrollListener(): void {
        const dataTableBody = this.getComponentBodyReference();
        if (dataTableBody) {
          dataTableBody.removeEventListener("scroll", this.onScroll);
        }
      },
      onScroll(event: any): void {
        const currentScrollPosition: number = event.target.scrollTop + event.target.clientHeight;
        const threshold: number = event.target.scrollHeight * 0.95; // Chrome not trigger with scrollbar, set the threshold
        if (currentScrollPosition >= threshold) {
          this.$emit("scroll", event);
        }
      },
      isRowSelected(index: number): boolean {
        const dataTable: BTable = this.getComponentReference();
        if (dataTable) {
          return dataTable.isRowSelected(index);
        }
        return false;
      },
      rowSelectionToggled(index: number): void {
        const dataTable: BTable = this.getComponentReference();
        if (dataTable) {
          if (dataTable.isRowSelected(index)) {
            dataTable.unselectRow(index);
          } else {
            dataTable.selectRow(index);
          }
        }
      },
      onRowSelected(items: any[]): void {
        if (this.enableEmitRowSelected) {
          (this as any).$emit("row-selected", items);
        }
      },
      extractGroupsFromFields(): FieldGroup[] {
        let groups: FieldGroup[] = [];
        let previousGroup: FieldGroup;
        for (let field of this.activeFields) {
          let key: String = '-';
          let label: String = '-';
          if (field.group) {
            key = field.group;
            label = field.group;
          }

          let existingGroup: FieldGroup;
          if (previousGroup && previousGroup.key === key) {
            existingGroup = previousGroup;
          }

          if (existingGroup) {
            existingGroup.fieldCount += 1;
          } else {
            const newGroup: FieldGroup = new FieldGroup(key, label, 1);
            groups.push(newGroup);
            previousGroup = newGroup;
          }
        }

        if (this.allActiveFieldsGroups().length === 0) {
          return [];
        }

        return groups;
      },
      allActiveFieldsGroups(): (String | undefined)[] {
        return (this.sortedFields || [])
          .filter((field: Field) => field.active && field.group !== undefined && field.group !== '' && field.group !== '-')
          .map((field: Field) => field.group);
      }
    },
    mounted(): void {
      this.registerScrollListener();
    },
    beforeDestroy() {
      this.unregisterScrollListener();
    }
  });
