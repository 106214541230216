import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeIso8601 } from "@/main/webapp/vue/model/api/DateTimeIso8601";

@JsonObject('DateTime')
export class DateTime extends DateTimeIso8601 {

  @JsonProperty('timeZone', String, true)
  timeZone?: string = undefined;

  @JsonProperty('date', String, true)
  date?: string = undefined;

  @JsonProperty('time', String, true)
  time?: string = undefined;

  @JsonProperty('timestamp', String, true)
  timestamp?: string = undefined;

  constructor(timeZone: string, date: string, time: string, timestamp: string) {
    super();
    this.timeZone = timeZone;
    this.date = date;
    this.time = time;
    this.timestamp = timestamp;
  }

}
