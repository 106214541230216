
  import Vue, { PropType } from 'vue';
  import { mapState } from "vuex";
  import { v1 as uuidv1 } from 'uuid';
  import messages from '../messages.json';

  import Quill from 'quill';
  // @ts-ignore
  import QuillBetterTable from 'quill-better-table';
  import basicModal from '@/main/webapp/vue/components/ui/modal/basic/index.vue';

  import { computedStyleUtil } from "@/main/webapp/vue/components/learning/util/computedStyleUtil";

  import TableWidgetModel, { TableWidgetProperties } from '@/main/webapp/vue/components/learning/widget/widgets/table/model/TableWidgetModel';
  import CourseModel from "@/main/webapp/vue/model/api/learning/CourseModel";
  import ActivityModel from "@/main/webapp/vue/model/learning/ActivityModel";

  export default Vue.extend({
    name: 'TableWidget',
    props: {
      model: {
        type: Object as PropType<TableWidgetModel>,
        required: true
      },
      course: {
        type: Object as PropType<CourseModel>,
        required: true
      },
      activity: {
        type: Object as PropType<ActivityModel>,
        required: true
      },
      editable: {
        type: Boolean,
        default: false
      },
      editing: {
        type: Boolean,
        default: false
      }
    },
    components: {
      basicModal
    },
    data() {
      return {
        editor: null as null | Quill,
        rows: 3 as number,
        columns: 3 as number,
        toolbarOptions: [
          [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ 'font': [] }],
          [{ 'color': [] }], // dropdown with defaults from theme
          [{ 'align': [] }],
          ['clean'] // remove formatting button
        ] as any[],
        uuid: uuidv1() as string
      };
    },
    computed: {
      ...mapState([
        'courseDefaultStyles'
      ]),
      computedWidgetClass(): String {
        return computedStyleUtil.getFromGenericStyle('styleClasses', this.model, this.computedDefaultStyle);
      },
      computedWidgetStyle(): Object {
        return computedStyleUtil.getStyles(
          ["margin"],
          this.model.properties.genericStyle,
          this.computedDefaultStyle.genericStyle);
      },
      computedDefaultStyle(): TableWidgetProperties {
        return computedStyleUtil.getDefaultProperties(this.model);
      },
      computedEditingStatus(): boolean {
        return this.editing;
      }
    },
    watch: {
      model: {
        handler(): void {
          this.$emit('edited', this.model, true);
        },
        deep: true
      }
    },
    methods: {
      showInfoModal(): void {
        this.showModal = true;
      },
      hideInfoModal(): void {
        this.showModal = false;
      },
      startEditing(): void {
        if (this.editable) {
          this.$emit('edit', this.model);
        }
      },
      update(): void {
        if (this.editor) {
          this.model.data.html = this.editor.root.innerHTML;
          this.$emit('edited', this.model, true);
        }
      },
      addTable(): void {
        if (this.editor) {
          const tableModule = this.editor.getModule('better-table');
          tableModule.insertTable(parseInt(`${this.rows}`), parseInt(`${this.columns}`));
        }
      },
      registerBetterTableToQuill(): void {
        let editorComponent: Element | undefined = this.$refs.editor as Element;
        if (editorComponent) {

          Quill.register({
            'modules/better-table': QuillBetterTable
          }, true);

          this.editor = new Quill(editorComponent, {
            modules: {
              toolbar: this.toolbarOptions,
              table: false,
              'better-table': {
                operationMenu: {
                  items: {
                    unmergeCells: {
                      text: 'Another unmerge cells name'
                    }
                  },
                  color: {
                    colors: ['black', 'green', 'red', 'yellow', 'blue', 'white'],
                    text: 'Background Colors:'
                  }
                }
              }
            },
            theme: 'snow',
            formats: ['bold', 'underline', 'header', 'italic']
          });

          if (this.model.data && this.model.data.html) { // Load saved html
            this.editor.root.innerHTML = this.model.data.html;
          } else {
            this.showInfoModal();
          }

          if (this.editor) { // Listening changes in vue way
            this.editor.on('text-change', () => this.update());
          }
        }
      }
    },
    mounted(): void {
      this.registerBetterTableToQuill();
    },
    i18n: {
      messages: messages
    }
  });
