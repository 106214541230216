
  import Vue, { PropType } from 'vue';
  import messages from '@/main/webapp/vue/components/map/messages.json';

  import thumbnailCard from "@/main/webapp/vue/views/submissions-view/thumbnail-view/thumbnail-card/index.vue";

  import { MapSubmissionDetails } from "@/main/webapp/vue/model/api/Map/MapSubmissionDetails";
  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";

  export default Vue.extend({
    components: {
      thumbnailCard
    },
    props: {
      item: {
        type: Object as PropType<MapSubmissionDetails>
      }
    },
    computed: {
      getShop(): HierarchicalEntity | null {
        if (this.item && this.item.itemMetadata) {
          return this.item.itemMetadata.filter((metadata: HierarchicalEntity) =>
            metadata.metadata && metadata.metadata.type === "shop")[0];
        }

        return null;
      }
    },
    i18n: {
      messages: messages
    }
  });
