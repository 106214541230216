
  import Vue from 'vue';
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages';

  import keyValueInputTable from "@/main/webapp/vue/components/ui/table/key-value-input-table/index.vue";
  import { FieldType } from "@/main/webapp/vue/components/ui/table/data/index.vue";

  export default Vue.extend({
    props: {
      rawData: {
        type: String,
        required: true
      }
    },
    components: {
      keyValueInputTable
    },
    data() {
      return {
        selectedItems: [] as any[],
        htmlHiddenInputKey: "#responsibleUsersDto" as string,
        htmlMockRequiredInputKey: "#responsibleUsers" as string,
        htmlCompletionThresholdKey: "#completionThreshold" as string,
        htmlCompletionThresholdInputKey: "#completionThresholdValue" as string,
        fieldType: FieldType,
        uniqueId: "userId" as string,
        displayKey: "userName" as string,
        displayValue: "shopCount" as string,
        overwriteValue: "target" as string,
        displayKeyCustomFunctions: {
          placeholder: this.$tc('text.filter.users', 0),
          validateState: (item: any, value: string): boolean => {
            if (item) {
              return Boolean(value);
            }
            return false;
          }
        },
        displayValueCustomFunctions: {
          validateState: (item: any, value: number): boolean => {
            if (item && item.metadata && item.metadata[this.displayValue]) {
              return value >= 1 && value <= parseInt(item.metadata[this.displayValue]);
            }
            return true;
          },
          validationErrorMessage: (item: any): string => {
            if (item && item.metadata) {
              return `${this.$t('generic.check.invalid-field', { field: this.$t('generic.target').toLowerCase(), max: item.metadata[this.displayValue] })}`;
            }
            return '';
          }
        }
      };
    },
    methods: {
      getHTMLElement(key: string): HTMLElement | null {
        return document.querySelector(key);
      },
      updateUsersDto(newData: any[]): void {
        const convertedData: any[] = newData.map((item: any) => ({
          "userId": parseInt(item[this.uniqueId]),
          "target": parseInt(item[this.displayValue])
        }));

        let metadataInput: HTMLInputElement | null = this.getHTMLElement(this.htmlHiddenInputKey) as HTMLInputElement;
        if (metadataInput) {
          metadataInput.value = `{ "list": ${JSON.stringify(convertedData)} }`;
        }

        // Update project threshold
        let htmlCompletionThreshold: HTMLInputElement | null =
          this.getHTMLElement(this.htmlCompletionThresholdKey) as HTMLInputElement;
        if (htmlCompletionThreshold) {
          htmlCompletionThreshold.value = convertedData.reduce((a: any, b: any) => {
            return a + b.target;
          }, 0).toString();

          // Also the slider input needs to be synced
          let htmlCompletionThresholdInput: HTMLInputElement | null =
            this.getHTMLElement(this.htmlCompletionThresholdInputKey) as HTMLInputElement;
          if (htmlCompletionThresholdInput) {
            htmlCompletionThresholdInput.value = htmlCompletionThreshold.value;
          }
        }
      },
      updateMockRequiredInput(newData: any[]): void {
        let mockValue: string = "";
        if (newData && newData.length > 0) {
          mockValue = "True"; // Just any value to mock
        }

        let mockInput: HTMLInputElement | null = this.getHTMLElement(this.htmlMockRequiredInputKey) as HTMLInputElement;
        if (mockInput) {
          mockInput.value = mockValue;

          // This is to validate required input
          mockInput.focus();
          setTimeout(() => {
            mockInput.blur();
          }, 100);
        }
      },
      update(newData: any): void {
        this.updateUsersDto(newData);
        this.updateMockRequiredInput(newData);
      },
      getSelectedUserIds(): void {
        let metadataInput: HTMLInputElement | null = this.getHTMLElement(this.htmlHiddenInputKey) as HTMLInputElement;
        if (metadataInput) {
          const metadata: any = JSON.parse(metadataInput.value);
          if (metadata) {
            this.selectedItems = metadata.list;
          }
        }
      }
    },
    created(): void {
      this.getSelectedUserIds();
    },
    mounted(): void {
      this.$nextTick(() => {
        this.updateMockRequiredInput(this.selectedItems);
      });
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
