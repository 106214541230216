
  import Vue, { PropType } from 'vue';
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

  import textBadge from "@/main/webapp/vue/components/ui/text/text-badge/index.vue";

  import { Item } from "@/main/webapp/vue/model/api/Item";
  import SubmissionUtil from "@/main/webapp/vue/util/submissionUtil";

  export default Vue.extend({
    props: {
      submission: {
        type: Object as PropType<Item>
      },
      slideIndex: {
        type: Number,
        default: 0
      },
      itemIndex: {
        type: Number,
        default: 0
      }
    },
    components: {
      textBadge
    },
    computed: {
      computedItemPageLink(): string | undefined {
        return SubmissionUtil.computedItemPageLink(this.submission, this.slideIndex, this.itemIndex);
      }
    },
    methods: {
      onMouseEnter(): void {
        this.$emit('mouse-enter');
      },
      onIconClicked(): void {
        this.$emit('click');
      }
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
