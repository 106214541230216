
  import Vue from 'vue';
  import { mapState } from "vuex";

  import { jsonMapToKeyValueString } from "@/main/webapp/vue/util/arrayUtil";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityRegistryDataSourceEvent } from "@/main/webapp/vue/model/api/EntityRegistryDataSourceEvent";
  import { EntityContainer } from "@/main/webapp/vue/model/api/EntityContainer";
  import { EntityRegistryClient } from "@/main/webapp/vue/model/api/EntityRegistryClient";

  import dataTable, { Field, FieldType } from '@/main/webapp/vue/components/ui/table/data/index.vue';
  import notification from "@/main/webapp/vue/notification";
  import webSocketRequest from "@/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue";
  import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";
  import { WebSocketTaskPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskPayload";
  import {
    WebSocketClientIdPayload
  } from "@/main/webapp/vue/model/api/web-socket/WebSocketCliendIdPayload";

  export default Vue.extend({
    components: {
      webSocketRequest,
      dataTable
    },
    props: {
      client: {
        type: EntityRegistryClient,
        required: true
      },
      refresh: {
        type: Boolean,
        default: null
      }
    },
    data() {
      return {
        linkedClient: this.client as EntityRegistryClient,
        events: [] as EntityRegistryDataSourceEvent[],
        syncEvent: null as EntityRegistryDataSourceEvent | null,
        fields: [
          {
            key: "timestamp",
            type: FieldType.TIMESTAMP
          },
          {
            key: "data",
            type: FieldType.TEXT,
            formatter: (value: any, key: any, item: any): string => {
              return jsonMapToKeyValueString(value, (k: string, v: any) => {
                return `${k}: ${v.occurrences > 1 ? v.occurrences : ''} ${v.message ? v.message : ''}\n`;
              });
            }
          }
        ] as Field[]
      };
    },
    watch: {
      refresh(newValue: boolean, oldValue: boolean) {
        if (newValue && newValue !== oldValue) {
          this.refreshEvents();
        }
      }
    },
    computed: {
      WebSocketTaskType() {
        return WebSocketTaskType;
      },
      externalSyncRequestPayload(): WebSocketTaskPayload {
        return this.syncRequestPayload(false);
      },
      internalSyncRequestPayload(): WebSocketTaskPayload {
        return this.syncRequestPayload(true);
      },
      ...mapState([
        'userDetails'
      ])
    },
    methods: {
      syncRequestPayload(internal: boolean = true): WebSocketTaskPayload {
        let payload = new WebSocketClientIdPayload();
        payload.clientId = this.linkedClient.id;
        payload.internal = internal;
        if (process.env.NODE_ENV !== 'production') {
          console.log(`Internal sync payload contains cliend ID: ${this.linkedClient.id}`);
        }
        return payload;
      },
      refreshEvents(): void {
        BackendIntegrationService.getEntityRegistryClientEvents(this.userDetails.organization, this.linkedClient)
          .then((eventContainer: EntityContainer<EntityRegistryDataSourceEvent>) => {
            this.$emit("refreshed");
            this.events = eventContainer.list;
          }).catch((error: Error) => {
            notification.fail("Events request failed!");
          });
      }
    },
    mounted() {
      this.events = this.client.events;
    }
  });
