
  import Vue from 'vue';
  import messages from './messages.json';

  import { BvModalEvent } from "bootstrap-vue";

  export default Vue.extend({
    props: {
      uuid: {
        type: String,
        required: true
      },
      title: {
        type: String
      },
      enableSavebutton: {
        type: Boolean,
        default: true
      },
      savebuttonTitle: {
        type: String
      },
      savebuttonDisabled: {
        type: Boolean,
        default: false
      },
      savebuttonStyleClass: {
        type: String,
        default: 'btn-primary'
      },
      cancelbuttonTitle: {
        type: String
      },
      disableClosing: {
        type: Boolean,
        default: false
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onHide(bvModalEvent: BvModalEvent): void {
        if (this.disableClosing) {
          bvModalEvent.preventDefault();
          this.$emit('attempted-closing');
        } else {
          this.hideModal();
        }
      },
      hideModal(): void {
        this.$emit('hide');
      },
      onSavebuttonClick(): void {
        this.hideModal();
        this.$emit('save-button-clicked');
      }
    },
    i18n: {
      messages: messages
    }
  });
