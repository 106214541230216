/*
 * @see WebSocketTaskDefinitions to define destination and other parameters of the task
 * In the backend, need to register in the enum with matching name WebSocketTaskType, and wire Controller, etc.
 * Also, add the translation for the task "initiator" snapshop-frontend/src/main/webapp/vue/components/web-socket/web-socket-status/web-socket-request/index.vue
 */
export enum WebSocketTaskType {
  CONTENT_PACKAGE = 'CONTENT_PACKAGE',
  CONTENT_REPORT = 'CONTENT_REPORT',
  RAW_DATA = 'RAW_DATA',
  CHECK_IN = 'CHECK_IN',
  PROJECT_LIST = 'PROJECT_LIST',
  PROJECT_STATUS = 'PROJECT_STATUS',
  PROJECT_USER_PERFORMANCE = 'PROJECT_USER_PERFORMANCE',
  FRAUD_DATA = 'FRAUD_DATA',
  SHOP_EXPORT = 'SHOP_EXPORT',
  SHOP_IMPORT = 'SHOP_IMPORT',
  DELETE_INACTIVE_SHOPS = 'DELETE_INACTIVE_SHOPS',
  SHOP_REGISTRY_SYNC = 'SHOP_REGISTRY_SYNC'
}
