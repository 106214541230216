import {
  Client,
  frameCallbackType,
  IFrame, IMessage,
  IPublishParams,
  messageCallbackType, StompHeaders,
  StompSubscription
} from "@stomp/stompjs";

import { WebSocketRequest } from "@/main/webapp/vue/model/api/web-socket/WebSocketRequest";

import applicationConfiguration from '@/main/webapp/vue/config/application/configuration';
import DomUtil from "@/main/webapp/vue/util/domUtil";

export class WebSocketService {
  public static readonly CSRF_HEADER_META_TAG_NAME: string = '_csrf_header';
  public static readonly CSRF_TOKEN_META_TAG_NAME: string = '_csrf';

  public static client: Client;
  public static readonly subscriptions: Map<string, StompSubscription> = new Map();
  public static readonly stompConnectUrl: string = "/connect";

  public connect(onConnectCallback: frameCallbackType,
                 onDisconnectCallback: frameCallbackType,
                 onStompErrorCallback: frameCallbackType): void {
    this.initialize(onConnectCallback, onDisconnectCallback, onStompErrorCallback);
  }

  public active(): boolean {
    if (WebSocketService.client === undefined) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService - The WebSocket client does not exist");
      }

      return false;
    } else {
      const active: boolean = WebSocketService.client.active;

      if (process.env.NODE_ENV !== 'production') {
        console.log(`WebSocketService - The WebSocket connection is '${active}'`);
      }

      return active;
    }
  }

  public subscribe(websocketDestination: string, onSubscribeCallback: messageCallbackType): void {
    if (!this.active()) {
      throw new Error("Could not subscribe, inactive client");
    }

    const stompSubscription: StompSubscription | undefined = WebSocketService.subscriptions.get(websocketDestination);
    if (stompSubscription !== undefined) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`WebSocketService - Already subscribed ${websocketDestination}`);
      }
    }

    const subscription: StompSubscription = WebSocketService.client.subscribe(websocketDestination, onSubscribeCallback);
    WebSocketService.subscriptions.set(websocketDestination, subscription);
  }

  public unsubscribe(websocketDestination: string): void {
    if (this.active()) {
      if (process.env.NODE_ENV !== 'production') {
        console.log(`WebSocketService - Unsubscribing ${websocketDestination}`);
      }

      const stompSubscription: StompSubscription | undefined = WebSocketService.subscriptions.get(websocketDestination);
      if (stompSubscription !== undefined) {
        stompSubscription.unsubscribe();
        WebSocketService.subscriptions.delete(websocketDestination);

        if (WebSocketService.subscriptions.size === 0) {
          if (process.env.NODE_ENV !== 'production') {
            console.log(`WebSocketService - Closing web socket connection since there are no active subscriptions`);
          }

          this.disconnect();
        }
      }
    }
  }

  public disconnect(): void {
    if (WebSocketService.client !== undefined) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService - Closed the WebSocket connection manually");
      }

      WebSocketService.client.deactivate();
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService - Failed to close the WebSocket connection - it was already closed");
      }
    }
  }

  public jsonRequest(request: WebSocketRequest): void {
    if (!this.active()) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService - JSON REQUEST FAILED - INACTIVE CLIENT");
      }
      throw new Error("Inactive client");
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log("WebSocketService - JSON REQUEST BEFORE");
      console.log(request);
    }

    const publishParameters: IPublishParams = {
      destination: request.subscriptionsDestination,
      body: JSON.stringify(request)
    };

    if (process.env.NODE_ENV !== 'production') {
      console.log("WebSocketService - JSON BODY");
      console.log(publishParameters.body);
    }

    WebSocketService.client.publish(publishParameters);
  }

  public binaryRequest(request: WebSocketRequest): void {
    if (!this.active()) {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService - BINARY REQUEST FAILED - INACTIVE CLIENT");
      }
      throw new Error("Inactive client");
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log("WebSocketService - BINARY REQUEST BEFORE");
      console.log(request);
    }

    const publishParameters: IPublishParams = {
      destination: request.subscriptionsDestination,
      // @ts-ignore Need to do this properly...
      binaryBody: request.payload?.file,
      headers: request.headers
    };

    publishParameters.headers["content-type"] = "application/octet-stream";

    if (process.env.NODE_ENV !== 'production') {
      console.log("WebSocketService - HEADERS");
      console.log(publishParameters.headers);

      console.log("WebSocketService - BINARY BODY");
      console.log(publishParameters.binaryBody);
    }

    WebSocketService.client.publish(publishParameters);
  }

  private initialize(onConnectCallback: frameCallbackType,
                     onDisconnectCallback: frameCallbackType,
                     onStompErrorCallback: frameCallbackType): void {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`WebSocketService - Creating new WebSocket client`);
    }
    const csrfHeaders: { [key: string]: string } = {};

    const csrfHeaderName: string | null = DomUtil.getMetaTagContent(WebSocketService.CSRF_HEADER_META_TAG_NAME);
    const csrfToken: string | null = DomUtil.getMetaTagContent(WebSocketService.CSRF_TOKEN_META_TAG_NAME);

    if (csrfHeaderName !== null && csrfToken !== null) {
      csrfHeaders[csrfHeaderName] = csrfToken;
    }

    WebSocketService.client = new Client({
      brokerURL: this.prepareBrokerURL(WebSocketService.stompConnectUrl),
      debug: function(text: string) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(`WebSocketService debug: ${text}`);
        }
      },
      connectHeaders: csrfHeaders,
      splitLargeFrames: applicationConfiguration.properties.websocket.splitLargeFrames,
      reconnectDelay: applicationConfiguration.properties.websocket.reconnectDelay,
      heartbeatIncoming: applicationConfiguration.properties.websocket.heartbeatIncoming,
      heartbeatOutgoing: applicationConfiguration.properties.websocket.heartbeatOutgoing,
      logRawCommunication: true
    });

    WebSocketService.client.onConnect = (receipt: IFrame) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService onConnect", receipt);
      }
      onConnectCallback(receipt);
    };
    WebSocketService.client.onDisconnect = (receipt: IFrame) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService onDisconnect", receipt);
      }
      onDisconnectCallback(receipt);
    };
    WebSocketService.client.onStompError = (receipt: IFrame) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log("WebSocketService onStompErrorCallback", receipt);
      }
      onStompErrorCallback(receipt);
    };
    WebSocketService.client.onWebSocketError = (error: Event) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error("WebSocket error", error);
      }
      onStompErrorCallback(error);
    };
    WebSocketService.client.onUnhandledFrame = (frame: IFrame) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error("WebSocket unhandled frame", frame);
      }
    };
    WebSocketService.client.onUnhandledMessage = (message: IMessage) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error("WebSocket unhandled message", message);
      }
    };

    if (process.env.NODE_ENV !== 'production') {
      console.log("WebSocketService - Establishing a connection through the WebSocket client");
    }

    WebSocketService.client.activate();
  }

  private prepareBrokerURL(path: string): string {
    // Create a relative http(s) URL relative to current page
    const url = new URL(path, window.location.href);
    // Convert protocol http -> ws and https -> wss
    url.protocol = url.protocol.replace('http', 'ws');

    return url.href;
  }

}

export const webSocketService = new WebSocketService();
