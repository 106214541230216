
  import Vue from 'vue';
  import messages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { ProjectProgressForUserContainer } from "@/main/webapp/vue/model/api/ProjectProgressForUserContainer";
  import { ProjectProgressForUser } from "@/main/webapp/vue/model/api/ProjectProgressForUser";

  import projectProgressBar from "@/main/webapp/vue/components/project/progress/bar/index.vue";
  import UriUtil from "@/main/webapp/vue/util/uriUtil";
  import selectList from "@/main/webapp/vue/components/ui/select/select-list/index.vue";
  import { NamedEntity } from "@/main/webapp/vue/model/api/NamedEntity";
  import {
    ProjectProgressForDepartmentContainer
  } from "@/main/webapp/vue/model/api/ProjectProgressForDepartmentContainer";
  import { ProjectProgressForDepartment } from "@/main/webapp/vue/model/api/ProjectProgressForDepartment";

  export default Vue.extend({
    components: {
      selectList,
      projectProgressBar
    },
    props: {
      projectId: {
        type: Number,
        required: false,
        default: null
      },
      showTitle: {
        type: Boolean,
        required: false,
        default: true
      },
      showTarget: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        departmentStandings: [] as ProjectProgressForDepartment[],
        userStandings: [] as ProjectProgressForUser[],
        filteredDepartment: null as NamedEntity | null,
        loading: true as boolean
      };
    },
    computed: {
      computedProjectId(): any {
        return this.projectId === -1
          ? (this.$route.query.pid ? this.filteredProjectId(this.$route.query.pid as string) : this.getProjectId()) : this.projectId;
      },
      filteredDepartmentStandings(): ProjectProgressForUser[] {
        return this.filteredDepartment === null ? this.departmentStandings : this.filterDepartmentStandingsByDepartment(this.filteredDepartment);
      },
      filteredUserStandings(): ProjectProgressForUser[] {
        return this.filteredDepartment === null ? this.userStandings : this.filterUserStandingsByDepartment(this.filteredDepartment);
      },
      departments(): (NamedEntity | undefined)[] {
        const seen = new Set();
        const uniqueDepartments = (this.userStandings || []).map((standing: ProjectProgressForUser) => standing.department).filter((department: NamedEntity | undefined) => department !== undefined).filter((department: NamedEntity | undefined) => {
          const duplicate = seen.has(department?.id);
          seen.add(department?.id);
          return !duplicate;
        });
        return uniqueDepartments;
      }
    },
    methods: {
      filteredProjectId(pid: string): string {
        return UriUtil.removeHash(pid);
      },
      getProjectId(): number {
        // For project map after project modal
        if (this.$route.fullPath.search('pid') > -1 && this.$route.hash) {
          let projectId = parseInt(this.$route.hash.split("&")[0].split("=")[1]);
          return projectId;
        }

        return this.projectId;
      },
      loadProjectProgress() {
        if (this.computedProjectId) {
          BackendIntegrationService.fetchProjectUsersStandings(this.computedProjectId)
            .then((progressContainer: ProjectProgressForUserContainer) => {
              this.userStandings = progressContainer.list;
            }).finally(() => {
              this.loading = false;
            });
          BackendIntegrationService.fetchProjectDepartmentsStandings(this.computedProjectId)
            .then((progressContainer: ProjectProgressForDepartmentContainer) => {
              this.departmentStandings = progressContainer.list;
            }).finally(() => {
              this.loading = false;
            });
        }
      },
      departmentName(department: NamedEntity): String | undefined {
        return department?.name;
      },
      departmentSelected(department: any): void {
        console.log(`Selected department: ${department.name}`);
        this.filteredDepartment = department;
      },
      departmentUnselected(): void {
        console.log(`Unselected department`);
        this.filteredDepartment = null;
      },
      filterDepartmentStandingsByDepartment(department: NamedEntity) : ProjectProgressForDepartment[] {
        return (this.departmentStandings || []).filter((standing: ProjectProgressForDepartment) => standing.department !== undefined && standing.department.id === department.id);
      },
      filterUserStandingsByDepartment(department: NamedEntity) : ProjectProgressForUser[] {
        return (this.userStandings || []).filter((standing: ProjectProgressForUser) => standing.department !== undefined && standing.department.id === department.id);
      }
    },
    mounted() {
      if (this.computedProjectId !== -1 && this.computedProjectId !== null) {
        this.loadProjectProgress();
      }
    },
    created() {
      let that = this;

      window.onpopstate = function(event: any) {
        if (that.$route.hash === "#project_users" && that.computedProjectId !== -1 && that.computedProjectId !== null) {
          that.loadProjectProgress();
        }
      };
    },
    i18n: {
      messages: messages
    }
  });
