
  import Vue from 'vue';
  import { mapState } from "vuex";
  import sharedMessages from "@/main/webapp/vue/config/internationalization/sharedMessages.json";

  import glyphiconSymbol from "@/main/webapp/vue/components/ui/glyphicon/glyphicon-symbol/index.vue";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

  /* To match: com.moremr.snapshop.bean.ThumbnailView */
  export enum SubmissionsView {
    feed = "feed",
    thumb = "thumbnail",
    list = "list",
    map = "map",
    dashboard = "dashboard"
  }

  export class FilterAttributes {
    view: SubmissionsView | null;
    parameters: string;

    constructor(view: SubmissionsView | null, parameters: string) {
      this.view = view;
      this.parameters = parameters;
    }
  }

  export default Vue.extend({
    components: {
      glyphiconSymbol
    },
    data() {
      return {
        viewTypes: {
          feed: SubmissionsView.feed,
          thumb: SubmissionsView.thumb,
          list: SubmissionsView.list,
          map: SubmissionsView.map,
          dashboard: SubmissionsView.dashboard
        },
        currentView: null as SubmissionsView | null
      };
    },
    computed: {
      ...mapState([
        'lastFilterAttributes'
      ])
    },
    methods: {
      isActive(view: SubmissionsView): boolean {
        return this.currentView === view;
      },
      getRoutePathFromView(view: SubmissionsView | null): RoutePath {
        let routePath: RoutePath = RoutePath.SUBMISSIONS_FEED;

        if (view === SubmissionsView.thumb) {
          routePath = RoutePath.SUBMISSIONS_THUMB;
        } else if (view === SubmissionsView.list) {
          routePath = RoutePath.SUBMISSIONS_LIST;
        } else if (view === SubmissionsView.map) {
          routePath = RoutePath.SUBMISSIONS_MAP;
        } else if (view === SubmissionsView.dashboard) {
          routePath = RoutePath.SUBMISSIONS_DASHBOARD;
        }

        return routePath;
      },
      getParameters(): string {
        let parameters = "";

        Object.keys(this.$route.query).forEach((key: string) => {
          if (key !== "view" && key !== "oid") {
            if (parameters.includes("?")) {
              parameters += "&";
            } else {
              parameters += "?";
            }

            const value = this.$route.query[key] ? `=${this.$route.query[key]}` : '';
            parameters += `${key}${value}`;
          }
        });

        return parameters;
      },
      saveLastViewInStore(view: SubmissionsView | null, parameters: string): void {
        const filterAttributes: FilterAttributes = new FilterAttributes(view, parameters);
        this.$store.dispatch('updateFilterAttributes', filterAttributes);
      },
      updateUrl(view: SubmissionsView | null): void {
        const routePath: RoutePath = this.getRoutePathFromView(view);
        let parameters: string = this.getParameters();
        if (parameters.length === 0) { // Previously saved parameters from filter
          parameters = this.lastFilterAttributes.parameters;
        }

        this.$router.push({ path: `${routePath}${parameters}` });
        this.saveLastViewInStore(view, parameters);
      },
      updateLocalStorage(view: SubmissionsView | null) { // Remove it once Vue migrate to Navigation
        if (view) {
          const localStorageName: string = "snapshop-submissions-view";
          localStorage.setItem(localStorageName, view);
        }
      },
      changeView(view: SubmissionsView | null): void {
        if (this.currentView && this.currentView === view) {
          return;
        }

        this.$emit('view-updated', view);
        this.currentView = view;
        this.updateUrl(view);
        this.updateLocalStorage(view); // Remove it once Vue migrate to Navigation
      }
    },
    mounted(): void {
      let view: SubmissionsView | null = this.$route.query.view as SubmissionsView;
      if (!view) {
        view = this.lastFilterAttributes.view; // Remember last view the user had visited
      }

      this.changeView(view);
    },
    i18n: {
      sharedMessages: sharedMessages
    }
  });
