import CollapsibleControlFactory from "@/main/webapp/vue/model/collapsibleControl";

export enum URL_PATH {
  TIMELINE = "/timeline.html",
  THUMBS = "/thumbs.html",
  ITEM = "/item.html",
  DEPARTMENT = "/departments.html",
  USERS = "/users.html",
  SHOPS = "/shops.html",
  CHAINS = "/chains.html",
  CATEGORIES = "/categories.html",
  PROJECTS = "/projects.html",
  UPLOAD = "/upload.html"
}

export const paths = {
  "/timeline.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/thumbs.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/item.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/departments.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/users.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/shops.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/chains.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/categories.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/projects.html": CollapsibleControlFactory.prototype.DEFAULT(),
  "/upload.html": CollapsibleControlFactory.prototype.DEFAULT()
};
