
  import Vue, { PropType } from 'vue';
  import { oc as Optional } from "ts-optchain";
  import { mapState } from "vuex";
  import messages from './messages.json';

  import textLink from '../../ui/text/text-link/index.vue';
  import textTranslated from '../../ui/text/text-translated/index.vue';
  import articleSubmission from './article-submission/index.vue';
  import collapsibleContent from '../../ui/collapsible/collapsible-content/index.vue';

  import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { FeedArticle } from "@/main/webapp/vue/model/api/FeedArticle";
  import { HierarchicalEntity } from "@/main/webapp/vue/model/api/HierarchicalEntity";
  import { Item } from "@/main/webapp/vue/model/api/Item";
  import { FeedArticleEntity } from "@/main/webapp/vue/model/api/FeedArticleEntity";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

  export default Vue.extend({
    components: {
      textLink,
      textTranslated,
      articleSubmission,
      collapsibleContent
    },
    props: {
      article: {
        type: Object as PropType<FeedArticle | Item>,
        required: true
      },
      fromFilter: {
        type: Boolean,
        default: false
      },
      itemIndex: {
        type: Number
      }
    },
    computed: {
      ...mapState([
        'collapsedTypes'
      ]),
      computedArticleEntity(): Item | FeedArticleEntity {
        return this.fromFilter ? this.article : this.article.entity;
      },
      computedArticleSubmission(): Item {
        return this.fromFilter ? this.article : this.article.entity.submission;
      },
      shouldCollapse: function(): boolean {
        let collapseType = this.collapsedTypes[RoutePath.TIMELINE];

        let collapsed: boolean = Optional(collapseType).collapsed();
        if (collapsed) {
          return collapsed;
        }

        let type: string | undefined = Optional(this.computedArticleEntity).metadata.type();
        return type !== 'item';
      },
      webLink: function(): string {
        let nav: NavigationLinks | undefined = Optional(this.computedArticleEntity).nav();
        if (nav) {
          return nav.webLink();
        } else if (Optional(this.computedArticleEntity).metadata.hierarchical()) {
          let children: HierarchicalEntity[] | undefined = Optional(this.computedArticleEntity).children();
          if (children) {
            let leafChild: HierarchicalEntity | undefined;
            for (let child of children) {
              leafChild = child.getLeafEntity(child);
            }

            let leafNav: NavigationLinks | undefined = Optional(leafChild).nav();
            if (leafNav) {
              return leafNav.webLink();
            }
          }
        }

        return '#';
      }
    },
    i18n: {
      messages: messages
    }
  });
