
  import Vue, { PropType } from 'vue';
  import messages from "../messages.json";
  import sharedMessages from '@/main/webapp/vue/config/internationalization/sharedMessages.json';

  import textButton from "@/main/webapp/vue/components/ui/text/text-button/index.vue";
  import { SearchData } from "@/main/webapp/vue/model/api/SearchData";

  export default Vue.extend({
    props: {
      items: {
        type: Array as PropType<SearchData[]>,
        default: () => []
      },
      selectedItemIds: {
        type: Array as PropType<string[]>,
        default: () => []
      },
      orientation: {
        type: String,
        default: "vertical"
      },
      multiSelect: {
        type: Boolean,
        default: false
      }
    },
    components: {
      textButton
    },
    data() {
      return {
        tempSelectedItemIds: this.selectedItemIds as string[],
        showScrollLeft: false as boolean,
        toggleWrapper: null as HTMLDivElement | null,
        mouseDown: false as boolean,
        startMousePosition: 0 as number,
        isDragging: false as boolean
      };
    },
    methods: {
      onMouseUp(e: MouseEvent): void {
        this.mouseDown = false;
      },
      onMouseDown(e: MouseEvent): void {
        this.mouseDown = true;
        this.startMousePosition = e.clientX;
      },
      onMouseMove(e: MouseEvent): void {
        if (this.mouseDown) {
          if (this.toggleWrapper) {
            const to: number = this.startMousePosition - e.clientX;
            this.toggleWrapper.scrollTo(to, 0);
            this.isDragging = Math.abs(to) > 5;
          }
        }
      },
      checkScroll(e: any): void {
        if (e && e.target) {
          const width: number = e.target.scrollWidth;
          const left: number = e.target.scrollLeft;
          const current: number = width - left;

          this.showScrollLeft = (current <= e.target.clientWidth);
        }
      },
      scrollToLeft(): void {
        if (this.toggleWrapper) {
          this.toggleWrapper.scrollTo(0, 0);
          this.showScrollLeft = false;
        }
      },
      scrollToRight(): void {
        if (this.toggleWrapper) {
          this.toggleWrapper.scrollTo(this.toggleWrapper.scrollWidth, 0);
          this.showScrollLeft = true;
        }
      },
      emitChanges(): void {
        if (this.tempSelectedItemIds && this.tempSelectedItemIds.length > 1) {
          this.$emit("item-selected", this.tempSelectedItemIds.join(","));
        } else if (this.tempSelectedItemIds && this.tempSelectedItemIds.length === 1) {
          this.$emit("item-selected", this.tempSelectedItemIds[0]);
        } else {
          this.$emit("item-selected", "");
        }
      },
      translate(data: SearchData): string {
        if (data) {
          if (data.labelKey) {
            return this.$tc(`text.${data.labelKey}`, 0, { fallback: data.label }).toString();
          } else if (data.label) {
            return data.label;
          }
        }
        return '';
      },
      isSelected(itemId: string | undefined): boolean {
        if (itemId) {
          return this.selectedItemIds.some((selectedItemId: string) => selectedItemId === itemId);
        }
        return false;
      },
      onClick(item: SearchData): void {
        if (this.isDragging) {
          this.isDragging = false;
          return;
        }

        if (this.isSelected(item.id)) {
          this.tempSelectedItemIds.forEach((tempSelectedItemId: string, index: number) => {
            if (tempSelectedItemId === item.id) {
              this.tempSelectedItemIds.splice(index, 1);
            }
          });
        } else {
          if (!this.multiSelect) {
            this.tempSelectedItemIds = [];
          }

          if (item && item.id) {
            this.tempSelectedItemIds.push(item.id);
          }
        }

        this.emitChanges();
      }
    },
    mounted(): void {
      this.toggleWrapper = this.$refs.listToggleWrapper as HTMLDivElement;
    },
    i18n: {
      messages: messages,
      sharedMessages: sharedMessages
    }
  });
