
  import Vue, { PropType } from 'vue';

  import { Item } from "@/main/webapp/vue/model/api/Item";

  import submissionMetadata from '../submission-metadata/index.vue';
  import commentSummary from '../../comment/comment-summary/index.vue';
  import contentToFullscreen from "@/main/webapp/vue/components/ui/content/contentToFullscreen/index.vue";

  export default Vue.extend({
    components: {
      commentSummary,
      submissionMetadata,
      contentToFullscreen
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      itemIndex: {
        type: Number
      }
    }
  });
