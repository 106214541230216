const properties = {
  api: {
    contextPath: '/rest/v1',
    timeout: 5000
  },
  feed: {
    threshold: {
      failure: {
        load: 10
      }
    }
  },
  learning: {
    threshold: {
      failure: {
        load: 10
      }
    }
  },
  comment: {
    container: {
      display: {
        max: 3
      }
    }
  },
  content: {
    container: {
      display: {
        max: 2
      }
    },
    image: {
      placeholder: {
        noImage: '/img/no-photo.jpg'
      },
      size: {
        thumbnail: { // TODO: Retrieve from backend
          width: 220,
          height: 220
        }
      }
    }
  },
  availableLocales: { // TODO: retrieve from backend
    en: 'en-gb', // UK
    da: 'da',
    fi: 'fi',
    sv: 'sv',
    nb: 'nb',
    zh: 'zh-cn'
  },
  timestampFormat: { // TODO: retrieve from backend
    db: {
      timestamp: "DD.MM.YYYY HH:mm",
      date: "DD.MM.YYYY",
      time: "HH:mm"
    },
    display: {
      timestamp: "L HH:mm",
      date: "L",
      time: "HH:mm"
    }
  },
  reporting: {
    maxItemsInContentPackage: 200,
    maxItemsInContentReport: 250,
    maxItemsInRawExcelDataReport: 50000,
    maxItemsInCheckInExcelDataReport: 50000,
    maxItemsInFraudDataReport: 50000
  },
  websocket: {
    splitLargeFrames: true,
    reconnectDelay: 1000,
    localReconnectDelay: 2000, // our own delay added so all reconnects don't happen in 1 millisecond
    reconnectionLimit: 5,
    heartbeatIncoming: 5000,
    heartbeatOutgoing: 5000
  },
  apiKeys: {
    google: "AIzaSyBjWRT4cgkaZES675GNVO0f6hji0Jdcu5M" // FIXME: retrieve from backend
  },
  security: {
    password: {
      validateDigits: '[0-9]',
      validateSpecialCharacters: '€øæ½!#¤%&=+?`@£$(){}[]^~*-_.:,;<>|/"\''
    }
  }
};

export default {
  properties
};
