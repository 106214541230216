
  import Vue, { PropType } from 'vue';

  import { Item } from "@/main/webapp/vue/model/api/Item";

  import submissionSummary from "@/main/webapp/vue/components/ui/submission/submission-summary/index.vue";
  import likeContainer from "@/main/webapp/vue/components/ui/like/like-container/index.vue";
  import commentContainer from "@/main/webapp/vue/components/ui/comment/comment-container/index.vue";
  import shareContainer from "@/main/webapp/vue/components/ui/share/share-container/index.vue";
  import infoContainer from "@/main/webapp/vue/components/ui/info/info-container/index.vue";

  export default Vue.extend({
    components: {
      submissionSummary,
      likeContainer,
      commentContainer,
      shareContainer,
      infoContainer
    },
    props: {
      submission: {
        type: Object as PropType<Item>,
        required: true
      },
      itemIndex: {
        type: Number
      }
    }
  });
