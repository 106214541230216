import { ItemContent } from "@/main/webapp/vue/model/api/ItemContent";
import { Item } from "@/main/webapp/vue/model/api/Item";
import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

export default class UriUtil {
  static setQueryParameters(uri: string, parameters: { [key: string]: string | number | undefined | null }) {
    let parameterString = '';
    let parameterStringIndex = uri.indexOf('?');
    if (parameterStringIndex >= 0) {
      parameterString = uri.substring(parameterStringIndex + 1);
    } else {
      parameterStringIndex = uri.length;
    }

    Object.entries(parameters)
      .forEach(([key, value]) => {
        // Can these be combined? Tried (^|&) with no luck. lookbehind (?<!\w) also not supported
        if (parameterString.search(new RegExp(`^${key}=`)) >= 0 ||
          parameterString.search(new RegExp(`&${key}=`)) >= 0) {
          parameterString = parameterString.replace(new RegExp(`^${key}=[^&]*`), `${key}=${value}`);
          parameterString = parameterString.replace(new RegExp(`&${key}=[^&]*`), `&${key}=${value}`);
        } else {
          parameterString = `${parameterString}${parameterString.length > 0 ? '&' : ''}${key}=${value}`;
        }
      });
    return `${uri.substring(0, parameterStringIndex)}?${parameterString}`;
  }

  static removeHash(url: string): string {
    if (url) {
      const separator: string = "#";
      return url.split(separator)[0];
    }
    return '';
  }
}
