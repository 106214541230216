
  import Vue, { PropType } from 'vue';

  import carousel from "@/main/webapp/vue/components/ui/content/carousel/index.vue";
  import fullscreenCarousel from "@/main/webapp/vue/components/ui/content/carousel/fullscreen-carousel/index.vue";
  import contentContainer from "@/main/webapp/vue/components/ui/content/content-container/index.vue";

  import { Item } from "@/main/webapp/vue/model/api/Item";
  import SubmissionUtil from "@/main/webapp/vue/util/submissionUtil";

  export default Vue.extend({
    props: {
      submission: {
        type: Object as PropType<Item>
      },
      itemIndex: {
        type: Number,
        default: 0
      },
      carouselMode: {
        type: Boolean,
        default: true
      },
      enableCollapse: {
        type: Boolean,
        default: true
      }
    },
    components: {
      contentContainer,
      carousel,
      fullscreenCarousel
    },
    data() {
      return {
        showFullscreen: false as boolean,
        slideIndex: 0 as number
      };
    },
    methods: {
      emitHover() {
        this.$emit('hover');
      },
      emitBlur() {
        if (!this.showFullscreen) {
          this.$emit('blur');
        }
      },
      showFullscreenCarousel(slideIndex: number): void {
        this.emitHover();
        if (!SubmissionUtil.checkIfNoSubmission(this.submission, 0, 0)) {
          this.slideIndex = slideIndex;
          this.showFullscreen = true;
        }
      },
      hideFullscreenCarousel(): void {
        this.showFullscreen = false;
        this.$emit('fullscreen-close');
        this.emitBlur();
      },
      updateSlideIndex(newIndex: number): void {
        this.slideIndex = newIndex;
        this.$emit('slide-index-change', newIndex);
      }
    }
  });
