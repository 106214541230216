
  import Vue, { PropType } from 'vue';

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { EntityContainer } from "@/main/webapp/vue/model/api/EntityContainer";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";

  import UriUtil from "@/main/webapp/vue/util/uriUtil";

  import dataTable from "@/main/webapp/vue/components/ui/table/data/index.vue";
  import search from "@/main/webapp/vue/components/ui/form/search/index.vue";
  import notification from "@/main/webapp/vue/notification";

  export default Vue.extend({
    components: {
      dataTable,
      search
    },
    props: {
      fields: {
        type: Array,
        required: true
      },
      prefilledData: {
        type: [] as any[],
        default: () => []
      },
      dataLink: {
        type: NavigationLink,
        default: null
      },
      loadDataToFillScreen: {
        type: Boolean,
        default: true
      },
      enableFieldSelector: {
        type: Boolean,
        default: false
      },
      enableRowSelector: {
        type: Boolean,
        default: false
      },
      searchLink: {
        type: NavigationLink,
        default: null
      },
      searchLinkParameter: {
        type: String,
        default: "q"
      },
      searchPlaceholderText: {
        type: String,
        default: "Search"
      },
      searchResultsText: {
        type: String,
        default: ""
      },
      emptyText: {
        type: String,
        default: null
      },
      loadingText: {
        type: String,
        default: null
      },
      loadingError: {
        type: String,
        default: null
      },
      loadMoreText: {
        type: String,
        default: ""
      },
      noMarginBottom: {
        type: Boolean,
        default: false
      },
      selectedItemIds: {
        type: Array as PropType<number[]>,
        default: () => []
      }
    },
    data() {
      return {
        data: [...this.prefilledData] as any[],
        totalResults: -1 as Number,
        preSearchData: [] as any[],
        dataLinkNext: undefined as NavigationLink | undefined,
        loadMore: false as boolean,
        loadMoreByScroll: Boolean(this.loadMoreText.length === 0) as Boolean,
        initialLoad: true as boolean,
        isLoading: false as boolean
      };
    },
    watch: {
      dataLink: function(newValue: NavigationLink, oldValue: NavigationLink) {
        if (newValue && newValue !== oldValue) {
          if (process.env.NODE_ENV !== 'production') {
            console.log("Lazy table data-link changed", newValue.href);
          }
          this.dataLinkNext = newValue;
          this.onDataLoaded();
        }
      },
      prefilledData: function(newValue: any[], oldValue: any[]) {
        if (newValue && newValue !== oldValue) {
          this.data = [...this.prefilledData];
          this.loadMore = false;
        }
      },
      loadMore: function(newValue: boolean, oldValue: boolean) {
        if (newValue && this.dataLinkNext) {
          this.loadData(this.dataLinkNext);
        }
      }
    },
    methods: {
      loadData(dataLink: NavigationLink): void {
        this.loadMore = false;
        if (!this.isLoading && dataLink && dataLink.href) { // TODO: Show the user when there is no more data to load
          this.isLoading = true;
          BackendIntegrationService.fetchEntityContainer(dataLink).then((container: EntityContainer<any>) => {
            if (container.list && container.list.length > 0) {
              this.$emit("data-loaded", container.list);
              this.data.push.apply(this.data, container.list);
            }

            // TODO remove this when ShopContainer changed to list from shops
            if (container.shops && container.shops.length > 0) {
              this.$emit("data-loaded", container.shops);
              this.data.push.apply(this.data, container.shops);
            }

            if (container.total !== undefined) {
              this.totalResults = container.total;
            }

            if (container.nav) {
              let next: NavigationLink | undefined =
                NavigationLinks.getLinkFromNavigation(NavigationLinkType.PAGE_NEXT, container.nav);
              this.dataLinkNext = next;

              if (next) {
                this.onDataLoaded();
              }
            } else {
              this.loadMore = false;
              this.dataLinkNext = undefined;
            }
          }).catch((e: Error) => {
            notification.fail(this.loadingError);
          }).finally(() => {
            this.isLoading = false;
            this.initialLoad = false;
          });
        }
      },
      onSearch(searchString: string): void {
        if (this.searchLink) {
          if (this.preSearchData.length === 0) {
            this.preSearchData = Array.from(this.data);
          }
          this.data = [];
          this.loadData(NavigationLink.weblink(UriUtil.setQueryParameters(this.searchLink.href, {
            [this.searchLinkParameter]: searchString
          })));
        }
      },
      onSearchClear(): void {
        if (this.preSearchData.length > 0) {
          this.data = Array.from(this.preSearchData);
        }
        this.preSearchData = [];
        this.totalResults = -1;
      },
      onScroll(event: any): void {
        if (this.loadMoreByScroll) {
          this.loadMore = true;
        }
      },
      onLoadMoreLinkClicked(): void {
        this.loadMore = true;
        this.loadData(this.dataLinkNext);
      },
      onDataLoaded(): void {
        this.loadMoreIfTableHeightNotFull();
      },
      loadMoreIfTableHeightNotFull() {
        let parentComponentHeight: number = this.$parent.$el.offsetHeight;
        let tableComponentHeight: number = this.$refs["data-table-lazy"].$children[0].$children[1].$el.clientHeight;
        let searchComponentHeight: number = this.searchLink ? (this.$refs["data-table-search"].$el.clientHeight) : 0;
        if (!this.loadMore && !this.isLoading && tableComponentHeight <= (parentComponentHeight - searchComponentHeight)) {
          this.loadMore = true;
        }
      }
    },
    mounted(): void {
      this.dataLinkNext = this.dataLink;
      this.loadMoreIfTableHeightNotFull();
    }
  });
