
  import Vue, { PropType } from 'vue';
  import loadingIcon from "@/main/webapp/vue/components/ui/loading-icon/index.vue";

  import { ItemContent, ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

  export default Vue.extend({
    props: {
      content: {
        type: Object as PropType<ItemContent>,
        required: true
      },
      type: {
        type: String as PropType<ItemContentType>,
        default: ItemContentType.THUMBNAIL
      },
      itemIndex: {
        type: Number
      },
      load: {
        type: Boolean,
        default: true
      }
    },
    components: {
      loadingIcon
    },
    data() {
      return {
        loading: true as boolean,
        pageReady: false as boolean,
        types: ItemContentType
      };
    },
    computed: {
      imgUrl(): string | null {
        if (this.content && this.content.nav) {
          let link: NavigationLink | undefined = NavigationLinks.getLinkFromNavigation(this.type, this.content.nav) || NavigationLinks.getLinkFromNavigation(ItemContentType.THUMBNAIL, this.content.nav);
          if (link) {
            return link.href;
          }
        }
        return null;
      }
    },
    watch: {
      load(newLoad: boolean, oldLoad: boolean) {
        if (newLoad && !this.pageReady) {
          this.pageReady = true;
        }
      }
    },
    mounted(): void {
      if (this.load) {
        this.pageReady = true;
      }
    }
  });
