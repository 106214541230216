
  import Vue from 'vue';

  import glyphiconBadgeLink from '../../glyphicon/glyphicon-badge-link/index.vue';
  import messages from './messages.json';

  import { CollapsibleControl } from "@/main/webapp/vue/model/collapsibleControl";

  export default Vue.extend({
    components: {
      glyphiconBadgeLink
    },
    props: {
      collapsed: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      contentLength: {
        type: Number
      },
      onlyIcon: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showContent: !this.collapsed as boolean
      };
    },
    computed: {
      icon: function(): string {
        if (this.showContent) {
          return 'minus';
        }

        return 'plus';
      },
      text: function(): string {
        if (this.showContent) {
          return this.$tc('text.hide', this.multiple ? 1 : 0);
        }

        return this.$tc('text.show', this.multiple ? 1 : 0, { count: this.contentLength });
      },
      onCollapsedStateUpdated: function() {
        return this.$store.state.collapsedTypes[this.$route.path];
      }
    },
    methods: {
      toggleContent() {
        this.showContent = !this.showContent;
      }
    },
    watch: {
      collapsed: function(newCollapsed: boolean, oldCollapsed: boolean): void {
        if (this.showContent !== !newCollapsed) {
          this.showContent = !newCollapsed;
        }
      },
      onCollapsedStateUpdated: function(collapsedState: CollapsibleControl): void {
        if (collapsedState.collapsed == null) {
          this.showContent = !this.collapsed;
        } else if (collapsedState.collapsed) {
          this.showContent = false;
        } else {
          this.showContent = true;
        }
      }
    },
    i18n: {
      messages: messages
    }
  });
