
  import Vue, { PropType } from 'vue';
  import { mapState } from 'vuex';
  import { oc as Optional } from "ts-optchain";
  import messages from './messages.json';

  import thumbnailCard from "@/main/webapp/vue/views/submissions-view/thumbnail-view/thumbnail-card/index.vue";
  import loadingNoResultLastArticle
    from "@/main/webapp/vue/views/submissions-view/ui/loading-noresult-lastarticle/index.vue";
  import scrollToTop from '@/main/webapp/vue/components/ui/scroll/scroll-to-top/index.vue';
  import notification from "@/main/webapp/vue/notification";

  import { BackendIntegrationService } from "@/main/webapp/vue/services/BackendIntegrationService";
  import { SubmissionContainer } from "@/main/webapp/vue/model/api/SubmissionContainer";
  import { NavigationLinks, NavigationLinkType } from "@/main/webapp/vue/model/api/NavigationLinks";
  import { NavigationLink } from "@/main/webapp/vue/model/api/NavigationLink";
  import { BackendError } from "@/main/webapp/vue/model/BackendError";
  import { Item } from '@/main/webapp/vue/model/api/Item';
  import { SearchCriteria } from "@/main/webapp/vue/model/api/SearchCriteria";
  import { RoutePath } from "@/main/webapp/vue/model/RoutePath";

  export default Vue.extend({
    components: {
      thumbnailCard,
      scrollToTop,
      loadingNoResultLastArticle
    },
    props: {
      searchCriteria: {
        type: Object as PropType<SearchCriteria>,
        default: () => {}
      },
      selectedSubmissionIds: {
        type: Array as PropType<number[]>,
        default: () => []
      }
    },
    data() {
      return {
        articles: [] as Item[],
        nextPage: undefined as NavigationLink | undefined,
        loadMore: false as boolean,
        isLoading: false as boolean,
        loadFailureCount: 0 as number,
        isLastArticle: false as boolean,
        fromFilter: false as boolean,
        selectedItemIds: [] as number[],
        filledHeightByItem: false as boolean
      };
    },
    computed: {
      ...mapState([
        'properties'
      ])
    },
    watch: {
      loadMore: function(newLoadMore: boolean, oldLoadMore: boolean): void {
        if (newLoadMore) {
          this.loadFeedArticles();
        }
      },
      searchCriteria(newSearchCriteria: SearchCriteria, oldSearchCriteria: SearchCriteria): void {
        this.clearFeed(true);
        this.setArticles(newSearchCriteria.results);
      }
    },
    methods: {
      clearFeed(fromFilter: boolean = false): void {
        this.articles = [];
        this.nextPage = undefined;
        this.isLastArticle = false;
        this.fromFilter = fromFilter;
        this.filledHeightByItem = false;
      },
      getNewFeed(): void {
        this.clearFeed();
        this.loadMore = true;
        this.loadFailureCount = 0;
      },
      setArticles(results: SubmissionContainer | undefined = undefined): void {
        if (results && results.list) {
          const articles: Item[] = results.list;
          if (articles && articles.length > 0) {
            this.articles.push.apply(this.articles, articles);
          }

          let nav: NavigationLinks | undefined = Optional(results).nav();
          if (nav) {
            let nextLink: NavigationLink | undefined = nav.getLink(NavigationLinkType.PAGE_NEXT);
            if (nextLink) {
              this.nextPage = nextLink;
              this.checkScroll();
            } else {
              this.loadNoMore();
            }
          } else {
            this.loadNoMore();
          }
        }
      },
      showMessage(message: string): void {
        notification.fail(message);
      },
      handleError(error: BackendError): void {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Feed loading failed', error);
        }

        this.loadFailureCount++;
        if (this.loadFailureCount >= (this as any).$properties.feed.threshold.failure.load) {
          this.showMessage(`${this.$t('error.loading.failed')}`);
          this.loadNoMore();
        }
      },
      loadFeedArticles(): void {
        this.loadMore = false;
        if (!this.isLoading && !this.isLastArticle) {
          this.isLoading = true;

          BackendIntegrationService.fetchSubmissionList(this.nextPage)
            .then((submissionContainer: SubmissionContainer) => {
              this.isLoading = false;
              this.setArticles(submissionContainer);
            }).catch((error: BackendError) => {
              this.isLoading = false;
              this.handleError(error);
            });
        }
      },
      findSelectedIndex(itemId: number): number {
        this.selectedItemIds = [...this.selectedSubmissionIds]; // Get new value all the time
        return this.selectedItemIds.findIndex((selectedItemId: number) => selectedItemId === itemId);
      },
      itemSelected(itemId: number): void {
        if (this.findSelectedIndex(itemId) < 0) {
          this.selectedItemIds.push(itemId);
          this.$emit("thumbnail-view-submissions-selected", this.selectedItemIds);
        }
      },
      itemUnselected(itemId: number): void {
        const selectedIndex: number = this.findSelectedIndex(itemId);
        if (selectedIndex >= 0) {
          this.selectedItemIds.splice(selectedIndex, 1);
          this.$emit("thumbnail-view-submissions-selected", this.selectedItemIds);
        }
      },
      checkScroll(): void {
        if (this.$route.fullPath.includes(RoutePath.SUBMISSIONS_THUMB)) {
          const documentElement: HTMLElement = document.documentElement;
          const currentScrollPosition: number = documentElement.scrollTop + documentElement.offsetHeight;
          const threshold: number = documentElement.scrollHeight * 0.7;

          if (!this.loadMore && !this.isLastArticle) {
            if (!this.filledHeightByItem) {
              if (documentElement.scrollHeight <= documentElement.clientHeight) { // For long/wide screen
                this.loadMore = true;
              } else {
                this.filledHeightByItem = true;
              }
            } else if (currentScrollPosition >= threshold) {
              this.loadMore = true;
            }
          }
        }
      },
      registerScrollEvent(): void {
        window.addEventListener('scroll', this.checkScroll);
      },
      unregisterScrollEvent(): void {
        window.removeEventListener('scroll', this.checkScroll);
      },
      loadNoMore(): void {
        this.loadMore = false;
        this.isLastArticle = true;
        this.nextPage = undefined;
      }
    },
    mounted(): void {
      this.registerScrollEvent();

      if (this.searchCriteria) {
        this.setArticles(this.searchCriteria.results);
      }
    },
    beforeDestroy(): void {
      this.unregisterScrollEvent();
    },
    i18n: {
      messages: messages
    }
  });
