import { WebSocketTaskType } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskType";
import { WebSocketTaskPayload } from "@/main/webapp/vue/model/api/web-socket/WebSocketTaskPayload";
import { WebSocketRequestType } from "@/main/webapp/vue/model/api/web-socket/WebSocketRequestType";

export class WebSocketRequest {

  webSocketTaskType: WebSocketTaskType;
  subscriptionsDestination: string;
  payload: WebSocketTaskPayload;
  headers: Map<string, string>;

  constructor(webSocketTaskType: WebSocketTaskType,
              requestType: WebSocketRequestType,
              subscriptionsDestination: string,
              payload: WebSocketTaskPayload,
              headers: Map<string, string> = new Map<string, string>()) {
    this.webSocketTaskType = webSocketTaskType;
    this.subscriptionsDestination = `${requestType.toString()}${subscriptionsDestination}`;
    this.payload = payload;
    this.headers = headers;

    // Copy over payload extra-data as headers, since it's what the backend can access
    if (payload && payload.extraData) {
      payload.extraData.forEach((value: string, key: string) => {
        this.headers.set(key, value);
      });
      if (process.env.NODE_ENV !== 'production') {
        console.log(`web-socket-request class - ${this.webSocketTaskType} - constructor(): `, this.headers);
      }
      this.headers = Object.fromEntries(this.headers);
      payload.extraData = null;
    }
  }
}
