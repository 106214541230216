
  import Vue, { PropType } from 'vue';

  import { ItemContent, ItemContentType } from "@/main/webapp/vue/model/api/ItemContent";
  import { NavigationLinks } from "@/main/webapp/vue/model/api/NavigationLinks";

  export default Vue.extend({
    props: {
      content: {
        type: Object as PropType<ItemContent>,
        required: true
      },
      width: {
        type: Number,
        required: false,
        default: null
      },
      height: {
        type: Number,
        required: false,
        default: null
      },
      itemIndex: {
        type: Number
      }
    },
    computed: {
      imgUrl(): string | null {
        if (this.content && this.content.nav) {
          let link = NavigationLinks.getLinkFromLinks(ItemContentType.THUMBNAIL, this.content.nav.links);
          if (link) {
            return link.href;
          }
        }
        return null;
      },
      imgWidth(): number {
        return this.width !== null ? this.width : (this as any).$properties.content.image.size.thumbnail.width;
      },
      imgHeight(): number {
        return this.height !== null ? this.height : (this as any).$properties.content.image.size.thumbnail.height;
      }
    }
  });
